import React from "react";
import { PostData } from "../../services/PostData";

import "./Profile.css";

import trofeo1 from "../../images/trofeo1.png";
import trofeo2 from "../../images/trofeo2.png";
import trofeo3 from "../../images/trofeo3.png";
import trofeo4 from "../../images/trofeo4.png";

function Profile() {
  const [userData, setUserData] = React.useState({});
  const [wonMatchesList, setWonMatchesList] = React.useState([]);
  React.useEffect(() => {
    if (localStorage.getItem("userData")) {
      let data = JSON.parse(localStorage.getItem("userData"));
      setUserData(data.userData);
      console.log(data.userData.id);
      PostData("getWonMatches", {
        user_id: data.userData.id,
        email: data.userData.mail,
      }).then((result) => {
        let wonMatches = result;
        console.log(wonMatches);
        setWonMatchesList(wonMatches);
      });
    }
  }, []);

  const getTrophy = (championshipId) => {
    let trophy = "trofeo1";
    switch (championshipId) {
      case "1":
        trophy = "trofeo1";
        break;
      case "2":
        trophy = "trofeo2";
        break;
      case "6":
        trophy = "trofeo3";
        break;
      case "7":
        trophy = "trofeo4";
        break;
      case "12":
        trophy = "trofeo2";
        break;
      case "13":
        trophy = "trofeo3";
        break;
      case "36":
        trophy = "trofeo4";
        break;
      case "17":
        trophy = "trofeo4";
        break;
      default:
        trophy = "trofeo4";
        break;
    }
    return trophy;
  };

  return (
    <div className="profileContainer" key={Date.now()}>
      <div
        className="profileCloseButton"
        onClick={() => window.location.reload()}
      >
        X
      </div>
      <h1>Mi Perfil</h1>
      <div className="profileDataContainer">
        <div className="profileDataRow">
          <div className="profileDataTitle">Nombre:</div>
          <div className="profileDataContent">{userData.name}</div>
        </div>
        <div className="profileDataRow">
          <div className="profileDataTitle">Correo Electrónico:</div>
          <div className="profileDataContent">{userData.mail}</div>
        </div>
        <div className="profileDataRow">
          <div className="profileDataTitle">Nombre de Usuario:</div>
          <div className="profileDataContent">{userData.username}</div>
        </div>
        <div className="profileDataRow">
          <div className="profileDataTitle">País:</div>
          <div className="profileDataContent">{userData.country}</div>
        </div>
      </div>
      <h1>Mis trofeos</h1>
      <div className="profileWinningsContainer">
        {wonMatchesList.map((match, index) => (
          <div className="profileWinningSlot" key={index}>
            <div className="trophyContainer">
              {getTrophy(match.championship_id) === "trofeo1" && (
                <img src={trofeo1} alt="" />
              )}
              {getTrophy(match.championship_id) === "trofeo2" && (
                <img src={trofeo2} alt="" />
              )}
              {getTrophy(match.championship_id) === "trofeo3" && (
                <img src={trofeo3} alt="" />
              )}
              {getTrophy(match.championship_id) === "trofeo4" && (
                <img src={trofeo4} alt="" />
              )}
            </div>
            <div className="winningDataContainer">
              <div className="winningDataItem">
                <div>
                  <strong>FECHA: </strong>
                </div>
                <div>{match.match_date}</div>
              </div>
              <div className="winningDataItem">
                <div>
                  <strong>PARTIDO: </strong>
                </div>
                <div>{match.match_name}</div>
              </div>
              <div className="winningDataItem">
                <div>
                  <strong>SALA: </strong>
                </div>
                <div>{match.room_name}</div>
              </div>
              <div className="winningDataItem">
                <div>
                  <strong>MARCADOR: </strong>
                </div>
                <div>
                  {match.local} - {match.visitor}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Profile;
