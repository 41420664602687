import React, { Component } from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { PostData } from "../../services/PostData";
//import Signup from "../Signup/Signup";
import Swal from "sweetalert2";

import "./ScoreOffering.css";

import tomyPopUp from "../../images/tomyPopUp.png";
import Login from "../Login/Login";

const formatter = new Intl.NumberFormat("de-DE", {
  //style: "number",
  //currency: "COP",
  minimumFractionDigits: 0,
});

class ScoreOffering extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      sku: this.props.sku,
      roomId: this.props.roomId,
      matchId: this.props.matchId,
      scoreOfferData: this.props.scoreOfferData,
      scoreOfferFor: this.props.scoreId,
      localTeam: this.props.localTeam,
      visitorTeam: this.props.visitorTeam,
      unity: this.props.unity,
      playerOffer: "",
      type_room: this.props.type_room,
      playerWantsRegister: false,
      showPublicForm: false,
      publicPlayerEmail: null,
      publicPlayerName: null,
      publicPlayersEmailList: [],
    };

    this.cancelOffer = this.cancelOffer.bind(this);
    this.playerOffer = this.playerOffer.bind(this);
    this.increaseOffer = this.increaseOffer.bind(this);
    this.decreaseOffer = this.decreaseOffer.bind(this);
    this.setMyOffer = this.setMyOffer.bind(this);
    this.saveOffer = this.saveOffer.bind(this);
  }

  componentDidMount() {
    //console.log(this.state.sku);
    window.history.replaceState("home", "subasta", `/rooms/${this.state.sku}`);
    //console.log(window.history.state);
    window.onpopstate = function (event) {
      window.history.go();
    };
    this.playerOffer();
    let postData = {
      roomId: this.state.roomId,
    };
    PostData("getPublicRoomOffers", postData).then((result) => {
      let responseJson = result;
      console.log(responseJson);
      let roomOfferslistRaw = responseJson.roomOffers;
      console.log(roomOfferslistRaw);
      let resultEmail = roomOfferslistRaw.map((a) => a.public_player_email);
      console.log(resultEmail);
      this.setState({ publicPlayersEmailList: [...resultEmail] });
    });
  }

  cancelOffer() {
    //console.log("cerrar oferta");
    window.history.go();
  }

  playerOffer() {
    let newOffer;
    let actualOffer = parseInt(this.state.scoreOfferData.offer);
    let unity = parseInt(this.state.unity);
    this.setState({ playerOffer: actualOffer + unity }, () => {
      newOffer = this.state.playerOffer;
    });
    return newOffer;
  }

  increaseOffer() {
    let actualPlayerOffer = parseInt(this.state.playerOffer);
    let unity = parseInt(this.state.unity);
    this.setState({ playerOffer: actualPlayerOffer + unity }, () => {
      console.log(`nueva oferta es: ${this.state.playerOffer}`);
    });
  }

  decreaseOffer() {
    let actualPlayerOffer = parseInt(this.state.playerOffer);
    let unity = parseInt(this.state.unity);
    let currentScoreOffer = parseInt(this.state.scoreOfferData.offer);
    let minimumOffer = currentScoreOffer + unity;
    if (this.state.playerOffer > minimumOffer) {
      this.setState({ playerOffer: actualPlayerOffer - unity }, () => {
        console.log(`nueva oferta es: ${this.state.playerOffer}`);
      });
    }
  }

  setMyOffer(e) {
    let myOffer = e.target.value;
    myOffer = parseInt(myOffer.split(".").join(""));
    this.setState({ playerOffer: myOffer });
    if (this.state.type_room === "5") {
      this.setState({ playerOffer: 1 });
    }
  }

  saveOffer() {
    console.log("guardamos la oferta en la BD");
    if (this.state.type_room !== "5") {
      let data = JSON.parse(localStorage.getItem("userData"));
      console.log("con estos datos de usuario: ", data.userData);

      let offerData = {};
      offerData.client_user_id = data.userData.id;
      offerData.roomId = this.state.roomId;
      offerData.cell = this.state.scoreOfferFor;
      offerData.bet = this.state.playerOffer;
      offerData.status = 1;
      console.log(" y con estos datos de la oferta: ", offerData);
      PostData("saveOffer", offerData).then((result) => {
        let responseJson = result;
        console.log(responseJson.offerUpdated);
        if (responseJson.offerUpdated === 1) {
          console.log("datos almacenados en la base de datos");
          window.history.go();
        } else {
          alert(result.error);
        }
      });
    } else {
      console.log(this.state.playerWantsRegister);
      let offerData = {};
      offerData.public_player_email = this.props.email
        ? this.props.email
        : this.state.publicPlayerEmail;
      offerData.public_player_name = this.props.name
        ? this.props.name
        : this.state.publicPlayerName;
      offerData.roomId = this.state.roomId;
      offerData.matchId = this.state.matchId;
      offerData.cell = this.state.scoreOfferFor;
      offerData.bet = this.state.playerOffer;
      offerData.gain = 0;
      offerData.type = 1;
      console.log(" y con estos datos de la oferta: ", offerData);
      if (
        this.state.publicPlayersEmailList.includes(
          offerData.public_player_email
        )
      ) {
        Swal.fire({
          title: "Atención",
          text: `Solo puedes realizar una oferta`,
          confirmButtonText: "Entendido",
          confirmButtonColor: "#16A64A",
          position: "top",
        }).then(() => this.cancelOffer());
      } else {
        if (
          offerData.public_player_email !== null &&
          offerData.public_player_name !== null
        ) {
          const regex =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
          if (regex.test(offerData.public_player_email) === false) {
            alert("usa un email válido");
          } else {
            PostData("savePublicOffer", offerData).then((result) => {
              let responseJson = result;
              console.log(responseJson.offerUpdated);
              if (responseJson.offerUpdated === 1) {
                console.log("datos almacenados en la base de datos");
                window.history.go();
              } else {
                alert(result.error);
              }
            });
          }
        } else {
          Swal.fire({
            title: "Atención",
            text: `debes diligenciar los datos para poder registrar el marcador`,
            confirmButtonText: "Entendido",
            confirmButtonColor: "#16A64A",
            position: "top",
          });
        }
      }
    }
  }

  render() {
    let offerMinimumValueError;
    let offerMultipleValueError;
    let scoreOfferFor = this.state.scoreOfferFor;
    let localScore = scoreOfferFor.substr(1, 1);
    let visitorScore = scoreOfferFor.substr(3, 1);
    let unity = parseInt(this.state.unity);
    console.log(this.state.scoreOfferData.offer);
    let offer = Number(this.state.scoreOfferData.offer);
    let playerOffer = this.state.playerOffer;
    let offerButtonActive, offerButtonDisabled;
    let enableOfferButon = true;
    console.log(playerOffer);
    if (offer >= playerOffer) {
      offerMinimumValueError = (
        <div className="subasta-scoreOffering-error">
          ¡El valor de tu oferta debe ser mayor a{" "}
          {formatter.format(this.state.scoreOfferData.offer)}!
        </div>
      );
      enableOfferButon = false;
    }
    if (playerOffer % unity !== 0) {
      offerMultipleValueError = (
        <div className="subasta-scoreOffering-error">
          ¡tu oferta debe ser un mútiplo de {formatter.format(this.state.unity)}
          !
        </div>
      );
      enableOfferButon = false;
    }
    if (enableOfferButon) {
      if (this.state.type_room === "1") {
        offerButtonActive = (
          <div className="btn btn-primary centered" onClick={this.saveOffer}>
            Ofertar
          </div>
        );
      } else {
        if (this.state.type_room === "2") {
          offerButtonActive = (
            <button
              type="submit"
              className="btn btn-primary centered"
              onClick={(e) => {
                e.preventDefault();
                this.saveOffer();
              }}
            >
              Seleccionar Marcador
            </button>
          );
        } else {
          if (
            (this.state.type_room === "5" && this.state.showPublicForm) ||
            (this.state.type_room === "5" && this.props.email)
          ) {
            offerButtonActive = (
              <>
                <button
                  type="submit"
                  className="btn btn-primary centered"
                  onClick={(e) => {
                    e.preventDefault();
                    this.saveOffer();
                  }}
                >
                  Seleccionar Marcador
                </button>
                <div className="subasta-scoreOffering-player-form-group">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://subastafutbolera.com"
                  >
                    Saber más acerca de Subasta Futbolera
                  </a>
                </div>
              </>
            );
          }
        }
      }
    } else {
      offerButtonDisabled = (
        <div className="btn btn-primary centered disabled">Ofertar</div>
      );
    }

    const numberMask = createNumberMask({
      prefix: "",
      suffix: "",
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ".",
      allowDecimal: true,
      decimalSymbol: ",",
      decimalLimit: 2, // how many digits allowed after the decimal
      integerLimit: 7, // limit length of integer numbers
      allowNegative: false,
      allowLeadingZeroes: false,
    });
    console.log(this.props);

    return (
      <React.Fragment>
        <div className="subasta-scoreOffering-wrapper">
          <div className="subasta-scoreOffering-container">
            <div className="subasta-scoreOffering-btn-container">
              <div className="btn btn-danger-small centered">
                <div onClick={this.cancelOffer}>X</div>
              </div>
            </div>
            <div className="subasta-scoreOffering-avatar-container">
              <img src={tomyPopUp} alt="" />
            </div>
            {this.state.type_room === "1" && (
              <>
                <h1>¿Quién da más?</h1>
                <div className="subasta-scoreOffering-score-container">
                  <div className="subasta-scoreOffering-score-teams">
                    <div className="subasta-scoreOffering-score-teamName">
                      <h2>{this.state.localTeam}</h2>
                    </div>
                    <div className="subasta-scoreOffering-score-teamName">
                      <h2>{this.state.visitorTeam}</h2>
                    </div>
                  </div>
                  <div className="subasta-scoreOffering-scores">
                    <div className="subasta-scoreOffering-score">
                      <h2>{localScore}</h2>
                    </div>
                    <div className="subasta-scoreOffering-score">
                      <h2>{visitorScore}</h2>
                    </div>
                  </div>
                </div>
                <div className="subasta-scoreOffering-actualOffer">
                  <div className="subasta-scoreOffering-actualOffer-value">
                    Oferta actual:
                    <br />
                    <span>{formatter.format(offer)}</span>
                  </div>
                  <div className="subasta-scoreOffering-actualOffer-playerName">
                    <span>{this.state.scoreOfferData.player}</span>
                  </div>
                </div>
                <div className="separator"></div>
                <div className="subasta-scoreOffering-myOffer">Mi Oferta:</div>
                <div className="subasta-scoreOffering-offer-form-container">
                  <div
                    className="subasta-scoreOffering-offer-button"
                    onClick={this.decreaseOffer}
                  >
                    -
                  </div>
                  <MaskedInput
                    mask={numberMask}
                    className="subasta-scoreOffering-offer-input"
                    value={playerOffer}
                    guide={false}
                    id="my-input-id"
                    onBlur={() => {}}
                    onChange={this.setMyOffer}
                  />
                  <div
                    className="subasta-scoreOffering-offer-button"
                    onClick={this.increaseOffer}
                  >
                    +
                  </div>
                </div>
                {offerMinimumValueError}
                {offerMultipleValueError}
                <p>
                  Unidad de aumento: <span>{formatter.format(unity)}</span>
                </p>

                {offerButtonDisabled}
                {offerButtonActive}
                <p className="subasta-scoreOffering-info">
                  ¡Quédate con este marcador mejorando la oferta!
                </p>
              </>
            )}
            {this.state.type_room === "2" && (
              <>
                <h1>Vas a seleccionar el marcador:</h1>
                <div className="subasta-scoreOffering-score-container">
                  <p>
                    Valor del Marcador: <span>{formatter.format(unity)}</span>
                  </p>
                  <div className="subasta-scoreOffering-score-teams">
                    <div className="subasta-scoreOffering-score-teamName">
                      <h2>{this.state.localTeam}</h2>
                    </div>
                    <div className="subasta-scoreOffering-score-teamName">
                      <h2>{this.state.visitorTeam}</h2>
                    </div>
                  </div>
                  <div className="subasta-scoreOffering-scores">
                    <div className="subasta-scoreOffering-score">
                      <h2>{localScore}</h2>
                    </div>
                    <div className="subasta-scoreOffering-score">
                      <h2>{visitorScore}</h2>
                    </div>
                  </div>
                </div>
                {offerButtonActive}
              </>
            )}
            {this.state.type_room === "5" && (
              <>
                <h3>Vas a seleccionar el marcador:</h3>
                <div className="subasta-scoreOffering-score-container">
                  <br></br>
                  <div className="subasta-scoreOffering-score-teams">
                    <div className="subasta-scoreOffering-score-teamName">
                      <h2>{this.state.localTeam}</h2>
                    </div>
                    <div className="subasta-scoreOffering-score-teamName">
                      <h2>{this.state.visitorTeam}</h2>
                    </div>
                  </div>
                  <div className="subasta-scoreOffering-scores">
                    <div className="subasta-scoreOffering-score">
                      <h2>{localScore}</h2>
                    </div>
                    <div className="subasta-scoreOffering-score">
                      <h2>{visitorScore}</h2>
                    </div>
                  </div>
                  <br></br>
                  <form>
                    {!this.props.email && (
                      <>
                        <br></br>
                        <div className="subasta-scoreOffering-player-form-group-row">
                          <label>¿Tienes cuenta o quieres registrarte?</label>
                          <input
                            type="button"
                            id="SI"
                            name="SI"
                            value="SI"
                            onClick={(e) =>
                              this.setState({ playerWantsRegister: true })
                            }
                            className="btn btn-primary btn-small"
                          />
                          <input
                            type="button"
                            id="NO"
                            name="NO"
                            value="NO"
                            onClick={(e) =>
                              this.setState({
                                showPublicForm: true,
                                playerWantsRegister: false,
                              })
                            }
                            className="btn btn-primary btn-small"
                          />
                        </div>
                        <br></br>
                        {this.state.showPublicForm && (
                          <>
                            <div className="subasta-scoreOffering-player-form">
                              <div className="subasta-scoreOffering-player-form-group">
                                <label>Nombre:</label>
                                <input
                                  type="text"
                                  name="public_player_name"
                                  id="public_player_name"
                                  onChange={(e) => {
                                    this.setState({
                                      publicPlayerName: e.target.value,
                                    });
                                    console.log(this.state.publicPlayerName);
                                  }}
                                  required
                                />
                              </div>
                              <div className="subasta-scoreOffering-player-form-group">
                                <label>Email:</label>
                                <input
                                  type="email"
                                  name="public_player_email"
                                  id="public_player_email"
                                  onChange={(e) => {
                                    this.setState({
                                      publicPlayerEmail: e.target.value,
                                    });
                                    console.log(this.state.publicPlayerEmail);
                                  }}
                                  required
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {this.state.playerWantsRegister && (
                      <div className="score_offering_embeded">
                        <Login></Login>
                      </div>
                    )}
                    {offerButtonActive}
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ScoreOffering;
