import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./PlayingRoomCard.css";
import { PostData } from "../../services/PostData";
import Swal from "sweetalert2";

import subastaRoomIcon from "../../images/subastaRoomIcon.png";
import classicRoomIcon from "../../images/classicRoomIcon.png";
import publicRoomIcon from "../../images/publicRoomIcon.svg";
import tomyPopUp from "../../images/tomyPopUp.png";

class PlayingRoomCard extends Component {
  constructor(props) {
    super(props);
    //console.log("datos ficha Player:", this.props.roomId);
    const roomId = { ...this.props.roomId };
    this.state = { ...roomId, match_id: "" };
    //console.log("new state: ", this.state.sku);
    this.getRoomData = this.getRoomData.bind(this);
    this.getMatchData = this.getMatchData.bind(this);
    this.copyRoomLink = this.copyRoomLink.bind(this);
    this.setMatchStatusText = this.setMatchStatusText.bind(this);
  }

  componentDidMount() {
    let data = JSON.parse(localStorage.getItem("userData"));
    this.setState({ userId: data.userData.id });
    this.getRoomData();
  }

  getRoomData() {
    const roomData = this.props.roomId;
    //console.log("Id de la sala: ", roomData);
    let postData = {
      room_id: roomData,
    };
    PostData("getRoomDataById", postData).then((result) => {
      let responseJson = result;
      //console.log(responseJson);
      let roomData = responseJson.roomData[0];
      //console.log("datos del partido", roomData);
      this.setState(
        {
          match_id: roomData.match_id,
          roomName: roomData.name,
          roomSku: roomData.sku,
          roomStatus: roomData.status,
          roomOwner: roomData.client_user_id,
          type_room: roomData.type_room,
        },
        () => {
          //console.log("id del partido: ", this.state.match_id);
          this.getMatchData();
          this.setState({ loading: false });
        }
      );
    });
  }

  getMatchData() {
    let postData = {
      match_id: this.state.match_id,
    };
    PostData("getMatchData", postData).then((result) => {
      let responseJson = result;
      let matchData = responseJson.matchData[0];
      //console.log(matchData);
      this.setState(
        {
          matchName: matchData.name,
          matchDate: matchData.date,
          matchHour: matchData.hour,
          fullMatchDate: "",
        },
        () => {
          //console.log(this.state.matchName);
          console.log(
            `date: ${this.state.matchDate}, hour: ${this.state.matchHour}`
          );
          const localizedTime = `${this.state.matchDate}T${this.state.matchHour}:00.000-05:00`; // example localized time string
          // Create a date object from the Colombian date and time string
          //const colombianDateTime = "2023-08-17T10:30:00.000-05:00"; // example Colombian date and time string
          const fullMatchDate = new Date(localizedTime);

          // Format the date and time in the user's locale and time zone
          const options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          };
          const browserDateTimeString = new Intl.DateTimeFormat(
            undefined,
            options
          ).format(fullMatchDate);

          console.log("final date: ", browserDateTimeString);
          this.setState({ fullMatchDate: browserDateTimeString });
          this.setMatchStatusText();
        }
      );
      this.setState({ loading: false });
    });
  }

  setMatchStatusText() {
    if (this.state.roomStatus === "1") {
      this.setState({ matchStatusText: "Activa" }, () => {
        //console.log(this.state.matchStatusText);
      });
    }
    if (this.state.roomStatus === "4") {
      this.setState({ matchStatusText: "Cerrada" }, () => {
        //console.log(this.state.matchStatusText);
      });
    }
  }

  copyRoomLink() {
    let copyText = `¡Hey!, te invito a jugar ${this.state.matchName} en Subasta Futbolera. Ingresa a: https://app.subastafutbolera.com/rooms/${this.state.roomSku} y libera la bestia!. Conoce más en https://subastafutbolera.com`;
    let tempInput = document.createElement("INPUT");
    tempInput.setAttribute("type", "text");
    tempInput.setAttribute("id", "textToCopy");
    tempInput.setAttribute("value", copyText);
    document.body.appendChild(tempInput);
    let element = document.getElementById("textToCopy");
    console.log(element);
    element.select();
    document.execCommand("copy");
    Swal.fire({
      imageUrl: tomyPopUp,
      imageWidth: 85,
      imageHeight: 130,
      title: "Listo!",
      text: `El enlace a la sala está copiado, compártelo en tus redes favoritas`,
      showConfirmButton: true,
      confirmButtonText: "Entendido",
      confirmButtonColor: "#16A64A",
      position: "top",
    });
  }

  render() {
    //const roomData = {...this.props.roomData};
    //console.log(this.state.sku);
    //console.log(this.state.userId);
    //console.log(this.state.roomOwner);
    if (this.state.userId) {
      return (
        <div className="subasta-room-container">
          <div className="subasta-room-header">
            {this.state.type_room === "1" && (
              <>
                <div className="subasta-room-type-icon">
                  <img src={subastaRoomIcon} alt="Sala tipo Subasta" />
                </div>
                <div className="subasta-room-type-title">SUBASTA</div>
              </>
            )}
            {this.state.type_room === "2" && (
              <>
                <div className="subasta-room-type-icon">
                  <img src={classicRoomIcon} alt="Sala tipo Subasta" />
                </div>
                <div className="subasta-room-type-title">CLÁSICA</div>
              </>
            )}
            {this.state.type_room === "5" && (
              <>
                <div className="subasta-room-type-icon">
                  <img src={publicRoomIcon} alt="Sala tipo Subasta" />
                </div>
                <div className="subasta-room-type-title">PÚBLICA</div>
              </>
            )}
            <div className="subasta-room-status">
              {this.state.matchStatusText}
            </div>
          </div>
          <div className="subasta-room-match-name-container">
            <div className="subasta-room-match-name">{this.state.roomName}</div>
          </div>
          <div className="subasta-room-data-container">
            <div className="subasta-romm-data-row">
              <div className="subasta-room-data-title">PARTIDO:</div>
              <div className="subasta-room-data-field">
                {this.state.matchName}
              </div>
            </div>
            <div className="subasta-romm-data-row">
              <div className="subasta-room-data-title">FECHA:</div>
              <div className="subasta-room-data-field">
                {this.state.fullMatchDate}
              </div>
            </div>
            <div className="subasta-romm-data-row">
              <div className="subasta-room-btn-container">
                <Link
                  to={"/Rooms/" + this.state.roomSku}
                  className="btn btn-primary-small"
                >
                  VER SALA
                </Link>
              </div>
              <div className="subasta-room-btn-container">
                <div
                  className="btn btn-primary-small"
                  onClick={this.copyRoomLink}
                >
                  COMPARTIR
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default PlayingRoomCard;
