import React, { Component } from "react";

import "./RoomSubastaFutbolera.css";
import NavSup from "../NavSup/NavSup";
import AddSystem from "../AddSystem/AddSystem";

class RoomSubastaFutbolera extends Component {
  render() {
    return (
      <React.Fragment>
        <NavSup />
        <AddSystem />
        <div className="room-container">
          <div>Sala Nombre</div>
          <div>Sala Datos</div>
        </div>
      </React.Fragment>
    );
  }
}

export default RoomSubastaFutbolera;
