import React, { Component } from "react";
import { PostData } from "../../services/PostData";
import Swal from "sweetalert2";

import "./RoomReport.css";
const formatter = new Intl.NumberFormat("de-DE", {
  //style: "number",
  //currency: "COP",
  minimumFractionDigits: 0,
});

export default class RoomReport extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      room_id: this.props.roomId,
      status: this.props.roomStatus,
      roomOffers: this.props.roomOffers,
      localTeamName: this.props.localTeamName,
      matchScoreLocal: this.props.matchScoreLocal,
      visitorTeamName: this.props.visitorTeamName,
      matchScoreVisitor: this.props.matchScoreVisitor,
      sku: this.props.sku,
      roomTotalFormated: "",
      fullPlayerReport: [],
      premium: this.props.premium,
      type: this.props.type,
      matchId: this.props.matchId,
      winners: [],
    };

    this.makeRoomReport = this.makeRoomReport.bind(this);
    this.calculateRoomTotalOffer = this.calculateRoomTotalOffer.bind(this);
    this.reload = this.reload.bind(this);
  }

  componentDidMount() {
    console.log(this.state.room_id);
    if (this.state.status === "1") {
      //alert("aqui status 1");
      Swal.fire({
        title: "Juega y gana!",
        text: "El partido no ha empezado, puedes seguir ofertando, cuando termine, aquí veras los resultados.",
        showConfirmButton: true,
        confirmButtonText: "Entendido",
        confirmButtonColor: "#16A64A",
        position: "top",
      });
      return;
    }
    if (this.state.type === "5") {
      //alert("aqui type 5");
      //alert(`matchID: ${this.state.matchId}`);
      let matchPostData = {
        matchId: this.state.matchId,
      };
      console.log(matchPostData);
      PostData("getMatchResult", matchPostData)
        .then((result) => {
          console.log(result);
          let matchResult = `L${result.local}V${result.visitor}`;
          console.log(matchResult);
          console.log(this.state.roomOffers[matchResult]);

          return matchResult;
        })
        .then((matchResult) => {
          //console.log(matchResult);
          let publicRoomWinnersPostData = {
            matchId: this.state.matchId,
            matchResult: matchResult,
          };
          PostData("getPublicRoomWinners", publicRoomWinnersPostData).then(
            (result) => {
              console.log(result);
              let publicRoomWinnerList = result.map((arr) => arr[0]);
              console.log(publicRoomWinnerList);
              this.setState({
                winners: [...publicRoomWinnerList],
              });
            }
          );
        });
      return;
    }
    if (this.state.status === "4" && this.state.type !== "5") {
      //alert("aqui status 4");
      this.setState({ loading: true });
      let postData = {
        room_id: this.state.room_id,
      };
      console.log(postData);
      PostData("getRoomPlayerWinnerId", postData).then((result) => {
        let roomWinnerName = "";
        let roomWinnerId = "";
        let responseJson = result;
        console.log(responseJson.roomPlayerWinnerData);
        let response = responseJson.roomPlayerWinnerData;
        console.log(response.length);
        if (response.length > 0) {
          let roomPlayerWinnerData = responseJson.roomPlayerWinnerData;
          let roomWinnerData = roomPlayerWinnerData[0];
          console.log(roomWinnerData);

          roomWinnerId = roomWinnerData.client_user_id;
          this.setState({ roomWinnerId: roomWinnerId }, () =>
            console.log(this.state.roomWinnerId)
          );

          postData = {
            client_user_id: this.state.roomWinnerId,
          };
          console.log("PARTIDO CON GANADOR");
        } else {
          roomWinnerId = "0";
          postData = {
            client_user_id: roomWinnerId,
          };
          console.log("PARTIDO SIN GANADOR");
        }

        PostData("getRoomPlayerWinnerName", postData).then((result) => {
          let responseJson = result;
          console.log(responseJson.roomPlayerWinnerName);
          let response = responseJson.roomPlayerWinnerName;
          if (response.length > 0) {
            let roomWinnerData = responseJson.roomPlayerWinnerName[0];
            roomWinnerName = roomWinnerData.name;
            this.setState({ roomWinnerName: roomWinnerName }, () => {
              console.log(this.state.roomWinnerName);
              this.makeRoomReport();
            });
          } else {
            roomWinnerName = "No hay ganador";
            this.setState({ roomWinnerName: roomWinnerName }, () => {
              console.log(roomWinnerName);
              this.makeRoomReport();
            });
          }
        });
        this.setState({ loading: false });
      });
      return;
    }
    if (this.state.status === "2") {
      Swal.fire({
        title: "Partido en curso",
        text: `Espera los resultados`,
        showConfirmButton: true,
        confirmButtonText: "Entendido",
        confirmButtonColor: "#16A64A",
        position: "top",
      });
    }
  }

  calculateRoomTotalOffer() {
    var roomTotal = 0;
    for (let l = 0; l < 6; l++) {
      for (let v = 0; v < 6; v++) {
        let scoreId = "L" + l + "V" + v;
        let celldataRaw = this.state.roomOffers[scoreId];
        let celldata = { ...celldataRaw };
        let offer = Number(celldata.offer);
        if (offer) {
          roomTotal += offer;
        }
      }
    }
    return roomTotal;
  }

  makeRoomReport() {
    let roomWinnerName = "";
    let roomWinnerId;
    if (this.state.roomWinnerId) {
      roomWinnerId = this.state.roomWinnerId;
    } else {
      roomWinnerId = "";
    }

    let roomTotal = this.calculateRoomTotalOffer();
    let roomTotalFormated = formatter.format(roomTotal);
    this.setState({ roomTotalFormated: roomTotalFormated });

    let playersList = this.state.roomOffers;
    delete playersList.isPlayer;
    delete playersList.isOwner;
    let playersListSorted = {};
    Object.keys(playersList).forEach(function (key) {
      playersListSorted[key] = playersList[key];
    });
    console.log(playersListSorted);
    let scoresListTitles = Object.keys(playersListSorted);
    let titlesA = scoresListTitles.map((score) => score.replace("L", "", "gi"));
    let titlesArray = titlesA.map((score) => score.replace("V", " - ", "gi"));
    console.log(titlesArray);
    let namesArray = [];
    Object.keys(playersListSorted).forEach(function (key) {
      console.log(key, playersListSorted[key].player);
      namesArray.push(playersListSorted[key].player);
    });
    console.log(namesArray);
    let offersArray = [];
    Object.keys(playersListSorted).forEach(function (key) {
      console.log(key, playersListSorted[key].offer);
      offersArray.push(playersListSorted[key].offer);
    });
    console.log(offersArray);

    if (this.state.premium === false) {
      let offersList = "<strong> Jugador : Marcador : Oferta</strong><br><br>";
      for (let cont = 0; cont < titlesArray.length; cont++) {
        console.log(titlesArray[cont]);
        offersList +=
          "<div>" +
          namesArray[cont] +
          " : " +
          titlesArray[cont] +
          " : " +
          offersArray[cont] +
          "</div>";
      }
      console.log(offersList);
      let report = "";
      report += "<h3>Ofertas:</h3>";
      report += offersList;
      console.log(report);
      this.setState({ fremiumReport: report });
    }

    const arrayOfPlayersList = Object.keys(playersListSorted).map((key) => {
      return playersListSorted[key];
    });
    console.log(arrayOfPlayersList);
    if (roomWinnerId != 0) {
      console.log(roomWinnerId);

      let winnerOffers = arrayOfPlayersList.filter((el) => {
        return el.playerId === roomWinnerId;
      });
      console.log(winnerOffers);

      let totalOfferWinner = 0;
      for (let i = 0; i < winnerOffers.length; i++) {
        console.log(winnerOffers[i].offer);
        let offerNumber = parseInt(winnerOffers[i].offer);
        console.log(offerNumber);
        totalOfferWinner += offerNumber;
      }

      console.log(`${roomWinnerName} ofertó un total de: ${totalOfferWinner}`);
      let totalOfferWinnerFormated = formatter.format(
        roomTotal - totalOfferWinner
      );
      this.setState({ totalOfferWinnerFormated: totalOfferWinnerFormated });
    }

    // get records by player
    console.log(arrayOfPlayersList);
    const filteredPlayerList = arrayOfPlayersList.reduce((acc, current) => {
      const x = acc.find((item) => item.playerId === current.playerId);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    console.log(filteredPlayerList);
    let fullPlayerReport = [];
    let playerReport = [];
    for (let j = 0; j < filteredPlayerList.length; j++) {
      console.log(filteredPlayerList[j].playerId);
      let postData = {
        client_user_id: filteredPlayerList[j].playerId,
        room_id: this.state.room_id,
      };
      console.log(postData);
      if (postData) {
        PostData("getRecordsByPlayer", postData).then((result) => {
          console.log(result.recordsByPlayer);
          let recordsByPlayer = result.recordsByPlayer;

          let recordByPlayerOffersSubtotal = 0;
          for (let k = 0; k < recordsByPlayer.length; k++) {
            console.log(recordsByPlayer[k].my_bet);
            recordByPlayerOffersSubtotal += parseInt(recordsByPlayer[k].my_bet);
            playerReport.push(
              <div key={k.toString()} className="report-text-container">
                <div className="report-text">{recordsByPlayer[k].name}</div>
                <div className="report-text">{recordsByPlayer[k].score}</div>
                <div className="report-text">
                  {formatter.format(recordsByPlayer[k].my_bet)}
                </div>
              </div>
            );
          }
          playerReport.push(
            <div className="subtotal">
              Subtotal: {formatter.format(recordByPlayerOffersSubtotal)}
            </div>
          );
          this.setState({ playerReport: playerReport }, () =>
            console.log(playerReport)
          );

          console.log(`el subtotal es: ${recordByPlayerOffersSubtotal}`);
          return playerReport;
        });
      }
    }
    fullPlayerReport.push(playerReport);
    this.setState({ fullPlayerReport: fullPlayerReport }, () =>
      console.log(this.state.fullPlayerReport)
    );
  }

  reload() {
    window.history.pushState("room", "subasta", `/rooms/${this.state.sku}`);
    window.history.go();
  }

  render() {
    console.log(this.state.premium);
    return (
      <React.Fragment>
        {this.state.premium ? (
          <div className="report-wrap">
            {this.state.type === "5" ? (
              <div className="report-container report-container-maxHeight">
                <h3>Marcadores</h3>
                <div className="report-score">
                  {this.state.localTeamName}
                  <strong>{this.state.matchScoreLocal}</strong>
                  {this.state.visitorTeamName}
                  <strong>{this.state.matchScoreVisitor}</strong>
                </div>
                <h3>Ganadores</h3>
                <div>
                  {this.state.winners.map((player) => {
                    return <div key={player}>{player}</div>;
                  })}
                </div>
                <div className="report-btn-container">
                  <button
                    className="btn btn-primary room-info-btn"
                    onClick={this.reload}
                  >
                    Entendido
                  </button>
                </div>
              </div>
            ) : (
              <div className="report-container">
                <h3>Marcadores</h3>
                <div className="report-score">
                  {this.state.localTeamName}
                  <strong>{this.state.matchScoreLocal}</strong>
                  {this.state.visitorTeamName}
                  <strong>{this.state.matchScoreVisitor}</strong>
                </div>
                <div className="report-heading-container">
                  <div className="report-heading">Ganador</div>
                  <div className="report-heading">Recompensa Sala</div>
                  <div className="report-heading">Recompensa Neta</div>
                </div>
                <div className="report-text-container">
                  <div className="report-text">{this.state.roomWinnerName}</div>
                  <div className="report-text">
                    {this.state.roomTotalFormated}
                  </div>
                  <div className="report-text">
                    {this.state.totalOfferWinnerFormated}
                  </div>
                </div>
                <div className="report-heading-container">
                  <div className="report-heading">Nombre</div>
                  <div className="report-heading">Marcador</div>
                  <div className="report-heading">Oferta</div>
                </div>
                {this.state.fullPlayerReport.map((playerReport) => (
                  <div className="report-player-container">{playerReport}</div>
                ))}
                <div className="report-btn-container">
                  <button
                    className="btn btn-primary room-info-btn"
                    onClick={this.reload}
                  >
                    Entendido
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="report-wrap">
            <div className="report-container">
              <h3>Marcadores</h3>
              <div className="report-score">
                {this.state.localTeamName}
                <strong>{this.state.matchScoreLocal}</strong>
                {this.state.visitorTeamName}
                <strong>{this.state.matchScoreVisitor}</strong>
              </div>
              <div className="report-heading-container">
                <div className="report-heading">Ganador</div>
                <div className="report-heading">Recompensa Sala</div>
                <div className="report-heading">Recompensa Neta</div>
              </div>
              <div className="report-text-container">
                <div className="report-text">{this.state.roomWinnerName}</div>
                <div className="report-text">
                  {this.state.roomTotalFormated}
                </div>
                <div className="report-text">
                  {this.state.totalOfferWinnerFormated}
                </div>
              </div>
              <div className="report-heading-container">
                <div className="report-heading">Nombre</div>
                <div className="report-heading">Marcador</div>
                <div className="report-heading">Oferta</div>
              </div>
              {this.state.fullPlayerReport.map((playerReport) => (
                <div className="report-player-container">{playerReport}</div>
              ))}
              <div className="report-btn-container">
                <button
                  className="btn btn-primary room-info-btn"
                  onClick={this.reload}
                >
                  Entendido
                </button>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
