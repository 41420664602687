import React, { Component } from "react";

import "./AddSystem.css";

class AddSystem extends Component {
  render() {
    return (
      <div className="subasta-add-system-container">Noticias Futboleras</div>
    );
  }
}

export default AddSystem;
