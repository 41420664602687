import React, { Component } from "react";
import { Link } from "react-router-dom";
import CreateRoomMenuComponent from "../CreateRoomMenuComponent/CreateRoomMenuComponent";

import "./NavInf.css";

import subastaCreateRoomBtnMobile from "../../images/subasta-createRomm-btn-mobile.png";
//import subastaSearchRoomBtnMobile from "../../images/subasta-searchRomm-btn-mobile.png";

class NavInf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToReferrer: false,
      viewCreateRoomsMenu: false,
    };
    this.logout = this.logout.bind(this);
    this.openCreateRoomMenuComponent =
      this.openCreateRoomMenuComponent.bind(this);
  }
  logout() {
    localStorage.setItem("userData", "");
    localStorage.clear();
    this.setState({ redirectToReferrer: true });
  }
  openCreateRoomMenuComponent(e) {
    var body;

    if (
      document.getElementsByClassName("subasta-dashboard-container").length > 0
    ) {
      console.log(
        document.getElementsByClassName("subasta-dashboard-container")
      );
      body = document.getElementsByClassName("subasta-dashboard-container");
      body[0].style.overflow = "hidden";
      this.setState({ viewCreateRoomsMenu: true });
    }
    //console.log(document.getElementsByClassName("subasta-room-wrap-container"));
    if (
      document.getElementsByClassName("subasta-room-wrap-container").length > 0
    ) {
      console.log(
        document.getElementsByClassName("subasta-room-wrap-container")
      );
      body = document.getElementsByClassName("subasta-room-wrap-container");
      //body[0].style.overflow = "hidden";
      this.setState({ viewCreateRoomsMenu: true });
    }
  }
  render() {
    let viewCreateRoomsMenu = this.state.viewCreateRoomsMenu;

    return (
      <div className="subasta-nav-inf-container mobile">
        <div className="subasta-nav-inf-icons-container">
          <div className="subasta-nav-inf-icon-container">
            <div
              className="subasta-nav-item"
              onClick={this.openCreateRoomMenuComponent}
            >
              <img
                className="subasta-room-icon"
                src={subastaCreateRoomBtnMobile}
                alt="Crear Sala"
              />
              <h4>CREAR SALA</h4>
            </div>

            {/* <Link to="/" onClick={this.logout} className="logout">
              SALIR
            </Link> */}
          </div>
        </div>
        {viewCreateRoomsMenu && (
          <div>
            <CreateRoomMenuComponent isAdmin={this.props.isAdmin} />
          </div>
        )}
      </div>
    );
  }
}

export default NavInf;
