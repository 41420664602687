import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Welcome from "././components/Welcome/Welcome";
import Home from "././components/Home/Home";
import Login from "././components/Login/Login";
import Signup from "././components/Signup/Signup";
import NotFound from "././components/NotFound/NotFound";
import FinishFacebookRegister from "./components/FinishFacebookRegister/FinishFacebookRegister";
import PasswordRecovery from "./components/PasswordRecovery/PasswordRecovery";
import RecoveryCode from "./components/RecoveryCode/RecoveryCode";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import Room from "./components/Room/Room";

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Welcome} />
      <Route path="/home" component={Home} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Route
        path="/finish-facebook-register"
        component={FinishFacebookRegister}
      />
      <Route path="/recovery" component={PasswordRecovery} />
      <Route exact path="/recovery-code" component={RecoveryCode} />
      <Route exact path="/change-password" component={ChangePassword} />
      <Route exact path="/rooms/:sku" component={Room} />
      <Route path="*" component={NotFound} />
    </Switch>
  </Router>
);

export default Routes;
