import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { PostData } from "../../services/PostData";
import "./Login.css";
//import Facebook from "../Facebook/Facebook";
import registerIcon from "../../images/registerIcon.png";
import excalamationIcon from "../../images/exclamationIcon.png";
import viewPass from "../../images/view-pass.svg";
import hidePass from "../../images/view-hide-pass.svg";

class Login extends Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",
      redirectToReferrer: false,
      viewPass: false,
    };

    this.login = this.login.bind(this);

    this.onChange = this.onChange.bind(this);

    this.goRegister = this.goRegister.bind(this);

    this.viewPassword = this.viewPassword.bind(this);
  }

  login() {
    if (this.state.email && this.state.password) {
      //console.log(this.state.email + " - " + this.state.password);
      PostData("login", this.state)
        .then((result) => {
          let responseJson = result;
          //console.log(responseJson);
          if (responseJson.userData) {
            localStorage.setItem("userData", JSON.stringify(responseJson));
            if (localStorage.getItem("room")) {
              let roomURL = localStorage.getItem("room");
              localStorage.removeItem("room");
              window.location.href = roomURL;
            } else {
              window.location.href = "/";
            }
            //return responseJson;
          } else {
            alert(result.error);
            return;
          }
        })
        .then((responseJson) => {
          //console.log(responseJson);
          PostData("userIsAdmin", { email: this.state.email })
            .then((result) => {
              let res = result;
              if (res.userAdminData) {
                let currentUser = JSON.parse(localStorage.getItem("userData"));
                currentUser = {
                  userData: { ...currentUser.userData, isAdmin: true },
                };
                localStorage.setItem("userData", JSON.stringify(currentUser));
                this.setState({ redirectToReferrer: true });
              }
              //console.log(JSON.stringify(res.userAdminData));
              //console.log("userIsAdmin", JSON.stringify(res.userAdminData));

              //console.log(currentUser);

              //console.log(currentUser);
            })
            .catch(() => console.log("user is not admin"));
        });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  viewPassword(e) {
    this.setState({ viewPass: !this.state.viewPass });
  }

  goRegister(e) {
    return <Redirect to={"/signup"} />;
  }

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/home"} />;
    }

    if (localStorage.getItem("userData")) {
      return <Redirect to={"/home"} />;
    }

    return (
      <div className="login-form">
        <div className="subasta-separator-title-gray">
          - sé parte de nuestra comunidad -
          <button className="btn btn-secondary centered">
            <img src={registerIcon} alt="Registrar" />
            <Link className="registerLink" to={"/signup"}>
              REGÍSTRATE AQUÍ
            </Link>
          </button>
        </div>

        <div className="subasta-separator-title">
          - si ya tienes cuenta, ingresa -
        </div>
        <input
          type="email"
          name="email"
          placeholder="Correo Electrónico"
          onChange={this.onChange}
        />
        <div className="subasta-pass-input">
          <input
            type={!this.state.viewPass ? "password" : "text"}
            name="password"
            placeholder="Contraseña"
            onChange={this.onChange}
            className="subasta-pass-field"
          />
          {!this.state.viewPass ? (
            <div className="subasta-pass-input-icon">
              <img
                src={viewPass}
                alt="ver contraseña"
                onClick={this.viewPassword}
              />
            </div>
          ) : (
            <div className="subasta-pass-input-icon">
              <img
                src={hidePass}
                alt="ocultar contraseña"
                onClick={this.viewPassword}
              />
            </div>
          )}
        </div>

        <input
          type="submit"
          value="INGRESAR"
          className="btn btn-primary centered"
          onClick={this.login}
        />
        <p>
          <Link to={"/recovery"}>
            <img src={excalamationIcon} alt="Olvidé mi contraseña" /> Olvidé mi
            contraseña
          </Link>
        </p>
        {/* 
        <div className="subasta-separator-title">- o ingresa con -</div>
        <div className="center-button">
          <Facebook />
        </div> 
        */}
        <div className="login-signup-accept-terms">
          <p>
            Al iniciar sesión acepto los &nbsp;
            <a
              href="//subastafutbolera.com/terminos-y-condiciones"
              target="_blank"
              rel="noopener noreferrer"
            >
              términos y condiciones
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default Login;
