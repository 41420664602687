import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./CreateRoomMenuComponent.css";
import CreateSubastaRoom from "../../components/CreateSubastaRoom/CreateSubastaRoom";
import CreateClassicRoom from "../../components/CreateClassicRoom/CreateClassicRoom";
import CreateOpenRoom from "../../components/CreateOpenRoom/CreateOpenRoom";

import closeCreateRoomsIcon from "../../images/close-createRooms-btn-mobile.png";
import createSubastaIcon from "../../images/create-subasta-icon.png";
import createMultipleIcon from "../../images/createmultipleIcon.png";
import createPublicIcon from "../../images/createPublicIcon.png";

import bulletIcon from "../../images/futbol-list-icon.png";

class CreateRoomMenuComponent extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      viewCreateSubastaRoom: false,
      viewCreateClassicRoom: false,
      viewCreateOpenRoom: false,
      viewSubastaRoomInfo: true,
      viewClassicRoomInfo: false,
      viewOpenRoomInfo: false,
      iconSubastaActive: true,
      iconClasicActive: false,
      iconOpenActive: false,
    };
    this.openCreateSubastaRoomComponent =
      this.openCreateSubastaRoomComponent.bind(this);
    this.openCreateClassicRoomComponent =
      this.openCreateClassicRoomComponent.bind(this);
    this.openCreateOpenRoomComponent =
      this.openCreateOpenRoomComponent.bind(this);
    this.viewSubastaInfo = this.viewSubastaInfo.bind(this);
    this.viewClassicInfo = this.viewClassicInfo.bind(this);
    this.viewOpenRoomInfo = this.viewOpenRoomInfo.bind(this);
  }
  componentDidMount() {
    window.history.pushState("home", "subasta", "/home");
    window.onpopstate = function (event) {
      window.history.go();
    };
  }
  openCreateSubastaRoomComponent(e) {
    //var body = document.getElementsByClassName("subasta-dashboard-container");
    //body[0].style.overflow = "hidden";
    this.setState({
      viewCreateSubastaRoom: true,
      viewCreateClassicRoom: false,
      viewCreateOpenRoom: false,
    });
  }
  openCreateClassicRoomComponent(e) {
    //var body = document.getElementsByClassName("subasta-dashboard-container");
    //body[0].style.overflow = "hidden";
    this.setState({
      viewCreateSubastaRoom: false,
      viewCreateClassicRoom: true,
      viewCreateOpenRoom: false,
    });
  }
  openCreateOpenRoomComponent(e) {
    //var body = document.getElementsByClassName("subasta-dashboard-container");
    //body[0].style.overflow = "hidden";
    this.setState({
      viewCreateSubastaRoom: false,
      viewCreateClassicRoom: false,
      viewCreateOpenRoom: true,
    });
  }
  viewSubastaInfo(e) {
    this.setState({
      viewSubastaRoomInfo: true,
      viewClassicRoomInfo: false,
      viewOpenRoomInfo: false,
      iconSubastaActive: true,
      iconClasicActive: false,
      iconOpenRoomActive: false,
    });
  }
  viewClassicInfo(e) {
    this.setState({
      viewClassicRoomInfo: true,
      viewSubastaRoomInfo: false,
      viewOpenRoomInfo: false,
      iconSubastaActive: false,
      iconClasicActive: true,
      iconOpenRoomActive: false,
    });
  }
  viewOpenRoomInfo(e) {
    this.setState({
      viewClassicRoomInfo: false,
      viewSubastaRoomInfo: false,
      viewOpenRoomInfo: true,
      iconSubastaActive: false,
      iconClasicActive: false,
      iconOpenRoomActive: true,
    });
  }
  render() {
    let createSubastaRoom = this.state.viewCreateSubastaRoom;
    let createClassicRoom = this.state.viewCreateClassicRoom;
    let createOpenRoom = this.state.viewCreateOpenRoom;
    let subastaInfo;
    if (this.state.viewSubastaRoomInfo) {
      subastaInfo = (
        <div className="room-info">
          <div className="room-info-separator"></div>
          <div className="room-info-title">Sala Subasta</div>
          <div className="room-info-description-title">
            Pujas por los marcadores, puedes quitárselo a alguien si ofreces
            más, comparte el link de la sala a tus amigos.
          </div>
          <div className="room-info-description">
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={bulletIcon} alt="item" />
              </div>
              <div className="room-info-description-text">
                <p>
                  Debes aumentar el valor del marcador para poder obtenerlo.
                </p>
              </div>
            </div>
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={bulletIcon} alt="item" />
              </div>
              <div className="room-info-description-text">
                <p>
                  <strong>
                    Se puede pujar por cualquier marcador hasta el inicio del
                    partido.
                  </strong>
                </p>
              </div>
            </div>
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={bulletIcon} alt="item" />
              </div>
              <div className="room-info-description-text">
                <p>
                  El jugador con el marcador final del partido gana la sala y el
                  trofeo digital.
                </p>
              </div>
            </div>
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={bulletIcon} alt="item" />
              </div>
              <div className="room-info-description-text">
                <p>Los reportes y cuentas se generan automáticamente.</p>
              </div>
            </div>
          </div>
          <div className="room-info-btn-container">
            <div
              className="btn btn-primary room-info-btn"
              onClick={this.openCreateSubastaRoomComponent}
            >
              CREAR SALA SUBASTA
            </div>
          </div>
        </div>
      );
    }
    if (this.state.viewClassicRoomInfo) {
      subastaInfo = (
        <div className="room-info">
          <div className="room-info-separator"></div>
          <div className="room-info-title">Sala Clásica</div>
          <div className="room-info-description-title">
            Escoge entre los marcadores que estén disponibles, nadie te lo va a
            quitar. Comparte el link a tus amigos.
          </div>
          <div className="room-info-description">
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={bulletIcon} alt="item" />
              </div>
              <div className="room-info-description-text">
                <p>Todos los marcadores tienen el mismo valor</p>
              </div>
            </div>
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={bulletIcon} alt="item" />
              </div>
              <div className="room-info-description-text">
                <p>
                  <strong>
                    Se pueden escoger hasta 2 marcadores por jugador
                  </strong>
                </p>
              </div>
            </div>
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={bulletIcon} alt="item" />
              </div>
              <div className="room-info-description-text">
                <p>
                  El jugador con el marcador final del partido gana la sala y el
                  trofeo digital.
                </p>
              </div>
            </div>
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={bulletIcon} alt="item" />
              </div>
              <div className="room-info-description-text">
                <p>Los reportes y cuentas se generan automáticamente.</p>
              </div>
            </div>
          </div>
          <div className="room-info-btn-container">
            <div
              className="btn btn-primary room-info-btn"
              onClick={this.openCreateClassicRoomComponent}
            >
              CREAR SALA CLÁSICA
            </div>
          </div>
        </div>
      );
    }
    if (this.state.viewOpenRoomInfo) {
      subastaInfo = (
        <div className="room-info">
          <div className="room-info-separator"></div>
          <div className="room-info-title">Sala Pública</div>
          <div className="room-info-description-title">
            Sala para jugar con grupos sociales grandes, juega con tu comunidad.
          </div>
          <div className="room-info-description">
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={bulletIcon} alt="item" />
              </div>
              <div className="room-info-description-text">
                <p>Muchos jugadores pueden escoger el mismo marcador</p>
              </div>
            </div>
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={bulletIcon} alt="item" />
              </div>
              <div className="room-info-description-text">
                <p>
                  <strong>Solo se puede escoger 1 marcador por jugador</strong>
                </p>
              </div>
            </div>
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={bulletIcon} alt="item" />
              </div>
              <div className="room-info-description-text">
                <p>
                  Los jugadores con el marcador final del partido ganan la sala
                  y trofeos digitales.
                </p>
              </div>
            </div>
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={bulletIcon} alt="item" />
              </div>
              <div className="room-info-description-text">
                <p>Los reportes y cuentas se generan automáticamente.</p>
              </div>
            </div>
          </div>
          <div className="room-info-btn-container">
            <div
              className="btn btn-primary room-info-btn"
              onClick={this.openCreateOpenRoomComponent}
            >
              CREAR SALA PÚBLICA
            </div>
          </div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div
          className="subasta-close-create-roomsIcon desktop"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <img src={closeCreateRoomsIcon} alt="" />
        </div>
        <div className="subasta-create-rooms-menu">
          <div className="subasta-create-rooms-btn-container mobile">
            <div className="btn btn-danger-small centered">
              <Link to={"/"}>X</Link>
            </div>
          </div>
          <h1>CREAR SALA</h1>
          <div className="subasta-create-rooms-items-container">
            <div
              className="subasta-create-rooms-item"
              onClick={this.viewSubastaInfo}
            >
              <div className="subasta-create-rooms-icon">
                <img src={createSubastaIcon} alt="Crea Sala tipo subasta" />
              </div>
              {this.state.iconSubastaActive ? (
                <div className="subasta-create-rooms-title-active">SUBASTA</div>
              ) : (
                <div className="subasta-create-rooms-title">SUBASTA</div>
              )}
            </div>
            <div
              className="subasta-create-rooms-item"
              onClick={this.viewClassicInfo}
            >
              <div className="subasta-create-rooms-icon">
                <img src={createMultipleIcon} alt="Crea Sala tipo Múltiple" />
              </div>
              {this.state.iconClasicActive ? (
                <div className="subasta-create-rooms-title-active">CLÁSICA</div>
              ) : (
                <div className="subasta-create-rooms-title">CLÁSICA</div>
              )}
            </div>
            {this.props.isAdmin && (
              <div
                className="subasta-create-rooms-item"
                onClick={this.viewOpenRoomInfo}
              >
                <div className="subasta-create-rooms-icon">
                  <img src={createPublicIcon} alt="Crea Sala tipo subasta" />
                </div>
                {this.state.iconOpenActive ? (
                  <div className="subasta-create-rooms-title-active">
                    PÚBLICA
                  </div>
                ) : (
                  <div className="subasta-create-rooms-title">PÚBLICA</div>
                )}
              </div>
            )}
          </div>
          {subastaInfo}
        </div>
        {createSubastaRoom && (
          <div className="subasta-createRoomsMenu-container">
            <CreateSubastaRoom />
          </div>
        )}
        {createClassicRoom && (
          <div className="subasta-createRoomsMenu-container">
            <CreateClassicRoom />
          </div>
        )}
        {createOpenRoom && (
          <div className="subasta-createRoomsMenu-container">
            <CreateOpenRoom />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default CreateRoomMenuComponent;
