import React, { Component } from "react";
import { PostData } from "../../services/PostData";
import { Redirect } from "react-router-dom";

import "../Welcome/Welcome.css";
import "../Signup/Signup.css";
import logoAppSmall from "../../images/logoAppSmall.png";

class FinishFacebookRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.location.state.name,
      userID: this.props.location.state.userID,
      email: this.props.location.state.email,
      country: "",
      username: "",
      fbRegisterFinished: false
    };
    this.finishSignupFB = this.finishSignupFB.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  finishSignupFB() {
    if (this.state.username && this.state.country && this.state.cellphone) {
      PostData("finishSignupFB", this.state).then(result => {
        let responseJson = result;
        if (responseJson.userData) {
          localStorage.setItem("userData", JSON.stringify(responseJson));
          this.setState({ fbRegisterFinished: true });
        } else alert(result.error);
      });
    } else {
      alert("Datos incompletos");
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    if (this.state.fbRegisterFinished) {
      return <Redirect to={"/home"} />;
    } else {
      return (
        <React.Fragment>
          <div className="signup-container">
            <div className="img-desktop-container">
              <div className="img-desktop-wrap"></div>
            </div>
            <div className="signup-form-container">
              <div className="signup-header-container">
                <div className="logoApp">
                  <img src={logoAppSmall} alt="Subasta Futbolera" />
                </div>
                <div className="signup-title">
                  <h2>Completa estos datos para completar el registro</h2>
                </div>
              </div>
              <div className="signup-form">
                <label htmlFor="">PAÍS</label>
                <select name="country" onChange={this.onChange}>
                  <option value="">Selecciona...</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Brasil">Brasil</option>
                  <option value="Chile">Chile</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Haití">Haití</option>
                  <option value="Honduras">Honduras</option>
                  <option value="México - CDMX">México - CDMX</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Panamá">Panamá</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Perú">Perú</option>
                  <option value="República Dominicana">
                    República Dominicana
                  </option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Venezuela">Venezuela</option>
                </select>
                <label htmlFor="">NOMBRE DE USUARIO</label>
                <input
                  type="text"
                  name="username"
                  placeholder="Nombre de Usuario"
                  onChange={this.onChange}
                />
                <input
                  type="submit"
                  className="btn btn-primary centered"
                  value="COMPLETAR REGISTRO"
                  onClick={this.finishSignupFB}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}
export default FinishFacebookRegister;
