import React, { Component } from "react";
import { PostData } from "../../services/PostData";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import Loader from "../Loader/Loader";
import NavSup from "../NavSup/NavSup";
import NavInf from "../NavInf/NavInf";
import AddSystem from "../AddSystem/AddSystem";
import ScoreOffering from "../ScoreOffering/ScoreOffering";
import RoomReport from "../RoomReport/RoomReport";
import CreateRoomMenuComponent from "../CreateRoomMenuComponent/CreateRoomMenuComponent";

import "./Room.css";

import roomIconShare from "../../images/roomIconShare.png";
import roomIconUsers from "../../images/roomIconUsers.png";
import roomIconReports from "../../images/roomIconReports.png";
import roomIconHome from "../../images/home-solid.png";
import userIcon from "../../images/userIcon.svg";

import roomIconReportsAlert from "../../images/roomIconReportsAlert.png";

import tomyPopUp from "../../images/tomyPopUp.png";

const formatter = new Intl.NumberFormat("de-DE", {
  //style: "number",
  //currency: "COP",
  minimumFractionDigits: 0,
});

class Room extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewRoom: false,
      roomIsFull: false,
      redirectToReferrer: false,
      sku: this.props.match.params.sku,
      roomData: null,
      roomOffers: {},
      loading: true,
      roomName: "",
      existentMatchData: [],
      matchId: "",
      roomId: "",
      unity: "",
      localTeamId: "",
      localTeamName: "",
      visitorTeamId: "",
      visitorTeamName: "",
      matchName: "Cargando...",
      makingOffer: false,
      unableToOffer: false,
      scoreOfferFor: "",
      roomTotal: 0,
      myOffers: 0,
      fullMatchDate: new Date(),
      offersByPlayer: [],
      showRoomReport: false,
      premium: false,
      totalPlayers: 0,
      offersByScore: [],
      fullRoomOfferList: {},
      browserDateTimeString: "",
      viewCreateRoomsMenu: false,
    };

    this.getRoomData = this.getRoomData.bind(this);
    this.getRoomOffers = this.getRoomOffers.bind(this);
    this.getMatchName = this.getMatchName.bind(this);
    this.getLocalTeamName = this.getLocalTeamName.bind(this);
    this.getVisitorTeamName = this.getVisitorTeamName.bind(this);
    this.drawScoreGrid = this.drawScoreGrid.bind(this);
    this.makeOffer = this.makeOffer.bind(this);
    this.calculateRoomTotalOffer = this.calculateRoomTotalOffer.bind(this);
    this.reload = this.reload.bind(this);
    this.seeMatchDate = this.seeMatchDate.bind(this);
    this.copyRoomLink = this.copyRoomLink.bind(this);
    this.seePlayersNameList = this.seePlayersNameList.bind(this);
    //this.seeRoomReport = this.seeRoomReport.bind(this);
    this.showSubastaRoomHelp = this.showSubastaRoomHelp.bind(this);
    this.makeRoomReport = this.makeRoomReport.bind(this);
    this.openRoomReport = this.openRoomReport.bind(this);
    this.unBlockRoom = this.unBlockRoom.bind(this);
    this.openCreateRoomMenuComponent =
      this.openCreateRoomMenuComponent.bind(this);
  }

  componentDidMount() {
    this.getRoomData();

    window.history.pushState("home", "subasta", window.location.href);
    window.onpopstate = function (event) {
      window.history.pushState("home", "subasta", "/home");
      window.history.go();
    };
    //console.log(this.props.match.params);

    if (this.state.makeOffer) {
      this.setState({ makeOffer: false });
    }
  }

  openCreateRoomMenuComponent(e) {
    var body = document.getElementsByClassName("subasta-room-wrap-container");
    console.log(body);
    // body[0].style.overflow = "hidden";
    // this.setState({ viewCreateRoomsMenu: true });
  }

  getRoomData() {
    let postData = {
      sku: this.state.sku,
    };
    PostData("getRoomData", postData)
      .then((result) => {
        let responseJson = result;
        this.setState({ roomData: responseJson.roomData }, () => {
          //console.log(this.state.roomData);
          let data = this.state.roomData[0];
          //console.log(data);
          this.setState(
            {
              roomName: data.name,
              matchId: data.match_id,
              unity: data.unity,
              roomId: data.id,
              status: data.status,
              type: data.type_room,
              plus: parseInt(data.plus),
            },
            () => {
              //console.log(this.state.roomId);
              this.getRoomOffers();
            }
          );
        });
        if (this.state.plus === 1) {
          this.setState({ premium: true });
        } else {
          this.setState({ premium: false });
        }
        this.setState({ loading: false });
      })
      .then(() => {
        if (localStorage.getItem("userData")) {
          let data = JSON.parse(localStorage.getItem("userData"));
          this.setState({
            name: data.userData.name,
            email: data.userData.mail,
            userId: data.userData.id,
            credits: data.userData.credits,
          });
          PostData("userExists", { email: this.state.email }).then((result) => {
            let responseJson = result;
            //console.log(responseJson.userExists);
            if (responseJson.userExists === 0) {
              //console.log("user no existe");
              localStorage.removeItem("userData");
              data = {};
              window.location.reload();
            }
          });
          //let data = localStorage.getItem("userData");
          //console.log(data);
        } else {
          console.log(localStorage.getItem("userData"));
          if (this.state.type === "5") {
            localStorage.setItem("room", window.location.href);
            //this.setState({ previewRoom: true });
            Swal.fire({
              title: "Atención",
              text: `Puedes jugar en esta sala sin estar registrado, con tu email se guardará tu marcador.`,
              confirmButtonText: "Entendido",
              confirmButtonColor: "#16A64A",
              position: "top",
            });
          } else {
            localStorage.setItem("room", window.location.href);
            //this.setState({ previewRoom: true });
            Swal.fire({
              title: "Atención",
              text: `Ingresa o Registrate para poder jugar en esta sala`,
              confirmButtonText: "Entendido",
              confirmButtonColor: "#16A64A",
              position: "top",
            }).then(() => {
              window.location.href = "/";
            });
          }
        }
      });
  }

  getRoomOffers() {
    let postData = {
      roomId: this.state.roomId,
    };
    if (this.state.type === "5") {
      //console.log("tipo de sala: Pública");
      PostData("getPublicRoomOffers", postData).then((result) => {
        let responseJson = result;
        //console.log(responseJson);
        let roomOfferslistRaw = responseJson.roomOffers;
        //console.log(roomOfferslistRaw);
        this.setState({ fullRoomOfferList: roomOfferslistRaw });
        let id = [];
        let playersNameList = [];
        let offersByScore = [];
        for (let key = 0; key < roomOfferslistRaw.length; key++) {
          //console.log(key + " : " + roomOfferslistRaw[key].id);
          id.push(Number(roomOfferslistRaw[key].id));
          playersNameList.push(roomOfferslistRaw[key].public_player_name);
          offersByScore.push(roomOfferslistRaw[key].score);
        }
        // console.log(id);
        // console.log(playersNameList);
        //console.log(offersByScore);
        this.setState({ offersByScore: [...offersByScore] });
        let offersByPlayersList = [];
        const playersNameListUnified = new Set(playersNameList);
        let playersUnifiedNameListArray = [...playersNameListUnified];
        //console.log(playersUnifiedNameListArray);
        this.setState(
          { playersNameList: playersUnifiedNameListArray },
          () => {}
          //console.log("listado de jugadores: ", this.state.playersNameList)
        );
        //console.log(this.state.playersNameList.includes(this.state.name));
        for (let i = 0; i < id.length; i++) {
          offersByPlayersList.push({ id: id[i], offers: 0 });
          for (let k = 0; k < id.length; k++) {
            if (id[i] === id[k]) {
              offersByPlayersList[i].offers += 1;
            }
          }
          // console.log(offersByPlayersList);
        }
        let prepareArray = function (arr) {
          return arr.map(function (elem) {
            if (typeof elem === "object" && elem !== null) {
              return JSON.stringify(elem);
            } else {
              return elem;
            }
          });
        };
        let set = new Set([...prepareArray(offersByPlayersList)]);
        let finalOffersByPlayersList = [...set];
        //console.log(finalOffersByPlayersList);
        //console.log(`total de jugadores: ${finalOffersByPlayersList.length}`);
        this.setState({ totalPlayers: finalOffersByPlayersList.length });
        if (
          this.state.premium === false &&
          finalOffersByPlayersList.length >= 36 &&
          this.state.playersNameList.includes(this.state.name) === false
        ) {
          this.setState({ roomIsFull: true });
        }
        //console.log(finalOffersByPlayersList);
        this.setState(
          { offersByPlayer: finalOffersByPlayersList },
          () => {}
          // console.log(
          //   "total de ofertas por jugador:",
          //   this.state.offersByPlayer
          // )
        );
        var keys = Object.keys(finalOffersByPlayersList);
        for (var i = 0; i < keys.length; i++) {
          let obj = JSON.parse(finalOffersByPlayersList[keys[i]]);

          if (obj.id === parseInt(this.state.userId)) {
            this.setState({ myOffers: obj.offers });
          }
        }

        let roomOffersList = {};
        for (let key in roomOfferslistRaw) {
          //console.log(roomOfferslistRaw[key].cell);
          let cellName = roomOfferslistRaw[key].score;
          roomOffersList[cellName] = {
            playerId: roomOfferslistRaw[key].id,
            player: roomOfferslistRaw[key].public_player_name,
            offer: roomOfferslistRaw[key].my_bet,
          };
        }
        //console.log("aqui va el nuevo objeto");
        //console.log(roomOffersList);
        this.setState({ roomOffers: roomOffersList }, () => {
          //console.log( "guardamos en el estado el objet, OfferList: ", this.state.roomOffers );
          this.getMatchName();
        });
      });
    } else {
      console.log("sala privada");
      PostData("getRoomOffers", postData).then((result) => {
        let responseJson = result;
        //console.log(responseJson);
        let roomOfferslistRaw = responseJson.roomOffers;
        //console.log(roomOfferslistRaw);
        let id = [];
        let playersNameList = [];
        for (let key = 0; key < roomOfferslistRaw.length; key++) {
          //console.log(key + " : " + roomOfferslistRaw[key].id);
          id.push(Number(roomOfferslistRaw[key].id));
          playersNameList.push(roomOfferslistRaw[key].name);
        }
        // console.log(id);
        // console.log(playersNameList);
        let offersByPlayersList = [];
        const playersNameListUnified = new Set(playersNameList);
        let playersUnifiedNameListArray = [...playersNameListUnified];
        //console.log(playersUnifiedNameListArray);
        this.setState(
          { playersNameList: playersUnifiedNameListArray },
          () => {
            //console.log("listado de jugadores: ", this.state.playersNameList);
          }
          //console.log("listado de jugadores: ", this.state.playersNameList)
        );
        //console.log(this.state.name);
        //console.log(this.state.playersNameList.includes(this.state.name));
        for (let i = 0; i < id.length; i++) {
          offersByPlayersList.push({ id: id[i], offers: 0 });
          for (let k = 0; k < id.length; k++) {
            if (id[i] === id[k]) {
              offersByPlayersList[i].offers += 1;
            }
          }
          //console.log(offersByPlayersList);
        }
        let prepareArray = function (arr) {
          return arr.map(function (elem) {
            if (typeof elem === "object" && elem !== null) {
              return JSON.stringify(elem);
            } else {
              return elem;
            }
          });
        };
        let set = new Set([...prepareArray(offersByPlayersList)]);
        let finalOffersByPlayersList = [...set];
        console.log(finalOffersByPlayersList);
        // console.log(`total de jugadores: ${finalOffersByPlayersList.length}`);
        this.setState({ totalPlayers: finalOffersByPlayersList.length });

        if (
          this.state.premium === false &&
          finalOffersByPlayersList.length >= 6 &&
          this.state.playersNameList.includes(this.state.name) === false
        ) {
          this.setState({ roomIsFull: true });
        }

        this.setState(
          { offersByPlayer: finalOffersByPlayersList },
          () => {}
          // console.log(
          //   "total de ofertas por jugador:",
          //   this.state.offersByPlayer
          // )
        );

        var keys = Object.keys(finalOffersByPlayersList);
        for (var i = 0; i < keys.length; i++) {
          let obj = JSON.parse(finalOffersByPlayersList[keys[i]]);

          if (obj.id === parseInt(this.state.userId)) {
            this.setState({ myOffers: obj.offers });
          }
        }

        let roomOffersList = {};
        for (let key in roomOfferslistRaw) {
          //console.log(roomOfferslistRaw[key].cell);
          let cellName = roomOfferslistRaw[key].cell;
          roomOffersList[cellName] = {
            playerId: roomOfferslistRaw[key].id,
            player: roomOfferslistRaw[key].name,
            offer: roomOfferslistRaw[key].bet,
          };
        }
        //console.log("aqui va el nuevo objeto");
        //console.log(roomOffersList);
        this.setState({ roomOffers: roomOffersList }, () => {
          // console.log(
          //   "guardamos en el estado el objeto OfferList: ",
          //   this.state.roomOffers
          // );
          this.getMatchName();
        });
      });
    }
  }

  getMatchName() {
    let postData = { match_id: this.state.matchId };
    PostData("getExistentMatchData", postData).then((result) => {
      let responseJson = result;
      this.setState(
        { existentMatchData: responseJson.existentMatchData },
        () => {
          let matchDataObj = this.state.existentMatchData[0];
          //console.log(matchDataObj);
          this.setState(
            {
              matchName: matchDataObj.name,
              localTeamId: matchDataObj.local_id,
              visitorTeamId: matchDataObj.visitor_id,
              matchDate: matchDataObj.date,
              matchHour: matchDataObj.hour,
              matchScoreLocal: matchDataObj.local,
              matchScoreVisitor: matchDataObj.visitor,
            },
            () => {
              // console.log(
              //   `los datos del partido son: ${this.state.matchName}, ${this.state.localTeamId}, ${this.state.visitorTeamId}`
              // );
              this.getLocalTeamName();
              this.getVisitorTeamName();

              let dateNow = new Date();

              let fullMatchDateRaw =
                this.state.matchDate + "T" + this.state.matchHour + ":00.000Z";
              console.log(fullMatchDateRaw);

              const colombianDateTime = `${this.state.matchDate}T${this.state.matchHour}:00.000-05:00`; // example Colombian date and time string
              const colDate = new Date(colombianDateTime); //Colombian match date

              // Format the date and time in the user's locale and time zone
              const options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              };
              const browserDateTimeString = new Intl.DateTimeFormat(
                undefined,
                options
              ).format(colDate);

              this.setState({ browserDateTimeString: browserDateTimeString });

              // console.log(`fecha partido: ${colDate}`);
              // console.log(`fecha actual: ${dateNow}`);

              // console.log(dateNow > colDate);

              this.setState(
                { fullMatchDate: colDate },
                () => {}
                // console.log(fullMatchDate)
              );
              // console.log(dateNow - testDate);
              if (dateNow > colDate) {
                if (this.state.status !== "4") {
                  this.setState(
                    { status: "2" },
                    () => {}
                    // console.log("estado actualizado")
                  );
                }
                this.setState(
                  { matchEnded: true },
                  () => {}
                  //console.log("El partido ya pasó")
                );
              }
            }
          );
        }
      );
    });
  }

  getLocalTeamName() {
    let postData = { teamId: this.state.localTeamId };
    PostData("getTeamName", postData).then((result) => {
      let responseJson = result;
      this.setState({ localTeamName: responseJson.teamName[0].name }, () => {
        //console.log(this.state.localTeamName);
      });
    });
  }

  getVisitorTeamName() {
    let postData = { teamId: this.state.visitorTeamId };
    PostData("getTeamName", postData).then((result) => {
      let responseJson = result;
      this.setState({ visitorTeamName: responseJson.teamName[0].name }, () => {
        // console.log(this.state.visitorTeamName);
      });
    });
  }

  makeOffer(score) {
    console.log(`Mis Ofertas: ${this.state.myOffers}`);
    if (this.state.status === "4") {
      Swal.fire({
        title: "Partido cerrado",
        confirmButtonText: "Ver Cuentas",
        confirmButtonColor: "#16A64A",
        position: "top",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.value) {
          this.openRoomReport();
        }
      });
    } else {
      if (this.state.matchEnded) {
        Swal.fire({
          title: "Partido en curso",
          text: `Espera los resultados`,
          showConfirmButton: true,
          confirmButtonText: "Entendido",
          confirmButtonColor: "#16A64A",
          position: "top",
        });
      } else {
        // sala publica max una oferta
        if (this.state.type === "5" && this.state.myOffers === 1) {
          Swal.fire({
            title: "Atención",
            text: `Solo puedes escoger un (1) marcador`,
            showConfirmButton: true,
            confirmButtonText: "Entendido",
            confirmButtonColor: "#16A64A",
            position: "top",
          });
          this.setState({ unableToOffer: true }, () => {
            console.log("no puedes hacer más ofertas clásica");
          });
          return;
        }
        // sala publica 0 ofertas
        if (this.state.type === "5" && this.state.myOffers === 0) {
          this.setState({ unableToOffer: false }, () => {
            console.log("puedes hacer una oferta en pública");
          });
          this.setState({ makingOffer: true, scoreOfferFor: score }, () => {
            console.log(`oferta para: ${this.state.scoreOfferFor} `);
          });
          return;
        }
        // sala subasta, premium más de 4 ofertas -> advertencia
        if (
          this.state.type === "1" &&
          this.state.premium === true &&
          this.state.myOffers >= 5
        ) {
          this.setState({ unableToOffer: true }, () => {
            console.log("no puedes hacer más ofertas subasta");
          });
          Swal.fire({
            title: "Atención",
            text: `Puedes hacer máximo 5 ofertas, si te quitan una, puedes hacer una nueva.`,
            showConfirmButton: true,
            confirmButtonText: "Entendido",
            confirmButtonColor: "#16A64A",
            position: "top",
          });
        }
        // sala clásica premium más de 2 ofertas --> Advertencia
        if (
          this.state.type === "2" &&
          this.state.premium === true &&
          this.state.myOffers > 2
        ) {
          Swal.fire({
            title: "Atención",
            text: `Puedes hacer todas las ofertas que quieras, solo recuerda dejar espacio para los demás, así el juego será mucho más interesante.`,
            showConfirmButton: true,
            confirmButtonText: "Entendido",
            confirmButtonColor: "#16A64A",
            position: "top",
          });
          return;
        }

        if (
          this.state.type === "1" &&
          this.state.premium === false &&
          this.state.myOffers < 4
        ) {
          this.setState({ makingOffer: true, scoreOfferFor: score }, () => {
            console.log(`oferta para: ${this.state.scoreOfferFor} `);
          });
        } else {
          if (
            (this.state.type === "2" &&
              this.state.premium === false &&
              this.state.myOffers < 2) ||
            this.state.premium === true
          ) {
            this.setState({ makingOffer: true, scoreOfferFor: score }, () => {
              console.log(`oferta para: ${this.state.scoreOfferFor} `);
            });
          } else {
            this.setState({ unableToOffer: true }, () => {
              console.log("no puedes hacer más ofertas");
            });
          }
        }
      }
    }
  }

  drawScoreGrid = () => {
    let scoreGridArray = [];
    for (let l = 0; l < 6; l++) {
      for (let v = 0; v < 6; v++) {
        let scoreClass = "score-box scoreL" + l + "V" + v;
        let scoreId = "L" + l + "V" + v;
        let celldataRaw = this.state.roomOffers[scoreId];
        //console.log(celldataRaw);
        let celldata = { ...celldataRaw };
        let celldataWithScore = {};
        celldataWithScore[scoreId] = { ...celldataRaw };
        //console.log(this.state.offersByScore);
        let offersByScore = this.state.offersByScore.filter(
          (score) => score === scoreId
        );
        //console.log(offersByScore);
        let offer = Number(celldata.offer);
        let drawOfferGrid;

        if (offer) {
          drawOfferGrid = (
            <React.Fragment>
              {this.state.type === "5" ? (
                <div className="subasta-scoreOffering-offerGrid-container">
                  <div className="subasta-scoreOffering-offerGrid-offersByScore">
                    {offersByScore.length}
                    <img
                      src={userIcon}
                      alt="Jugadores"
                      width="19px"
                      height="19px"
                    />
                  </div>
                </div>
              ) : (
                <div className="subasta-scoreOffering-offerGrid-container">
                  {formatter.format(offer)}
                  <div className="subasta-scoreOffering-offerGrid-OfferPlayerName">
                    {celldata.player.substring(0, 10)}
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        }

        if (this.state.type === "2" || this.state.type === "5") {
          scoreGridArray.push(
            <div
              className={scoreClass}
              key={scoreId}
              onClick={() => {
                if (this.state.type === "2" && celldata.player) {
                  this.setState({ unableToOffer: true });
                  //alert(`marcador no disponile. Usado por ${celldata.player}`);
                } else {
                  this.makeOffer(scoreId);
                }
              }}
            >
              {drawOfferGrid}
            </div>
          );
        }
        if (this.state.type === "1") {
          scoreGridArray.push(
            <div
              className={scoreClass}
              key={scoreId}
              onClick={() => {
                this.makeOffer(scoreId);
              }}
            >
              {drawOfferGrid}
            </div>
          );
        }
      }
    }
    return scoreGridArray;
  };

  calculateRoomTotalOffer() {
    var roomTotal = 0;
    for (let l = 0; l < 6; l++) {
      for (let v = 0; v < 6; v++) {
        let scoreId = "L" + l + "V" + v;
        let celldataRaw = this.state.roomOffers[scoreId];
        let celldata = { ...celldataRaw };
        let offer = Number(celldata.offer);
        if (offer) {
          roomTotal += offer;
        }
      }
    }
    return roomTotal;
  }

  reload() {
    window.history.pushState("room", "subasta", `/rooms/${this.state.sku}`);
    window.history.go();
  }

  seeMatchDate() {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timezone: "America/Bogota",
      timeZoneName: "short",
    };
    const date = new Date(this.state.fullMatchDate);
    Swal.fire({
      title: "Fecha del Partido",
      text: date.toLocaleString(undefined, options),
      showConfirmButton: true,
      confirmButtonText: "Entendido",
      confirmButtonColor: "#16A64A",
      position: "top",
    });
  }

  seePlayersNameList() {
    const nameList = this.state.playersNameList;
    console.log(nameList);
    const offersAndNameList = this.state.roomOffers;
    console.log(this.state.roomOffers);
    let html = "";
    if (this.state.type === "5") {
      console.log(this.state.fullRoomOfferList);
      html += `<div class="table-desc"><p>Bienvenido a Subasta Futbolera, te encuentras en una sala pública, puedes escoger un marcador y participar en nuestro ranking público.</p><hr></div><table class="playerListTable"><tr><th>JUGADOR</th><th>MARCADOR<span class="table-small-text">(${this.state.localTeamName} - ${this.state.visitorTeamName})</span></th></tr>`;

      // Object.keys(offersAndNameList).forEach(function (key, index) {
      //   html += `<tr><td>${offersAndNameList[key].player}</td><td>${key
      //     .replace("L", "")
      //     .replace("V", " - ")}</td></tr>`;
      //   return html;
      // });

      let fullOffersList = this.state.fullRoomOfferList;
      Object.keys(fullOffersList).forEach(function (key, index) {
        console.log(fullOffersList[key].public_player_name);
        html += `<tr><td>${
          fullOffersList[key].public_player_name
        }</td><td>${fullOffersList[key].score
          .replace("L", "")
          .replace("V", " - ")}</td></tr>`;
        return html;
      });
      // nameList.map((name) => {
      //   html += "<p>" + name + "</p>";
      //   return html;
      // });
      html += "</table>";
    } else if (!this.state.premium) {
      nameList.map((name) => {
        html += "<p>" + name + "</p>";
        return html;
      });
      let textOfferQuantityAllowed = 0;
      if (this.state.type === "2") {
        textOfferQuantityAllowed = "2";
      }
      if (this.state.type === "1") {
        textOfferQuantityAllowed = "4";
      }
      html += `<hr><div><p>La versión gratuita permite 6 jugadores y hasta ${textOfferQuantityAllowed} marcadores por jugador, si deseas quitar estos límites usa una llave y desbloquea todas las funcionalidades de la sala</p><a href="https://subastafutbolera.com/shop" target="_blank" class="btn btn-members">Comprar llaves</a></div>`;
    } else {
      nameList.map((name) => {
        html += "<p>" + name + "</p>";
        return html;
      });
    }

    Swal.fire({
      imageUrl: tomyPopUp,
      imageWidth: 85,
      imageHeight: 130,
      title: "Jugadores en la Sala",
      html: html,
      showConfirmButton: true,
      confirmButtonText: "Entendido",
      confirmButtonColor: "#16A64A",
      position: "top",
    });
  }

  copyRoomLink() {
    let copyText = `¡Hey!, te invito a jugar ${this.state.matchName} en Subasta Futbolera. Ingresa a: https://app.subastafutbolera.com/rooms/${this.state.sku} y libera la bestia!. Conoce más en https://subastafutbolera.com`;
    let tempInput = document.createElement("INPUT");
    tempInput.setAttribute("type", "text");
    tempInput.setAttribute("id", "textToCopy");
    tempInput.setAttribute("value", copyText);
    document.body.appendChild(tempInput);
    let element = document.getElementById("textToCopy");
    console.log(element);
    element.select();
    document.execCommand("copy");
    Swal.fire({
      imageUrl: tomyPopUp,
      imageWidth: 85,
      imageHeight: 130,
      title: "Listo!",
      text: `El enlace a la sala está copiado, compártelo en tus redes favoritas`,
      showConfirmButton: true,
      confirmButtonText: "Entendido",
      confirmButtonColor: "#16A64A",
      position: "top",
    });
  }

  showSubastaRoomHelp() {
    let textByRoomType = "";
    if (this.state.type === "2") {
      textByRoomType = `<h4>Hoy te encuentras en una sala tipo <strong>CLÁSICA</strong> con las siguientes características:</h4>
        <ul class='subasta-help'>
        <li>Tablero con marcadores desde el 0 - 0 hasta el 5 - 5.</li>
        <li><strong>Los jugadores pueden escoger dos (2) marcadores disponibles antes de que inicie el partido.</strong></li>
        <li>El valor de los marcadores es fijo, el mismo para todos.</li>
        <li>La recompensa de la sala es la suma del valor de todos los marcadores.</li>
        <li>Para el marcador final se tienen en cuenta hasta 120 minutos del partido.</li>
        <li>Los cálculos y reportes del juego se generan automáticamente al cerrar la sala con el marcador final.</li>`;
    }
    if (this.state.type === "1") {
      textByRoomType = `<h4>Hoy te encuentras en una sala tipo <strong>SUBASTA</strong> con las siguientes características:</h4>
        <ul class='subasta-help'>
        <li>Tablero con marcadores desde el 0 - 0 hasta el 5 - 5.</li>
        <li><strong>Los jugadores compiten por los marcadores hasta que el partido inicie.</strong></li>
        <li>Se obtiene un marcador mejorando la oferta.</li>
        <li>El valor de los marcadores va aumentando debido a las ofertas de los jugadores.</li>
        <li>La recompensa de la sala es la suma del valor de todos los marcadores.</li>
        <li>Para el marcador final se tienen en cuenta hasta 120 minutos del partido.</li>
        <li>Los cálculos y reportes del juego se generan automáticamente al cerrar la sala con el marcador final.</li>`;
    }
    if (this.state.premium) {
      Swal.fire({
        //imageUrl: ayudaSalaSubasta,
        html: `<h3>Hola, quizás llegaste acá como invitado o creador de la sala. Bienvenido.</h3><p>Subasta Futbolera es un sitio web creado para los fanáticos del fútbol, los pronósticos y las estrategias.</p><p>EL tablero digital permite jugar con los marcadores de partidos en 3 diferentes modalidades. Clásica, Subasta, Pública. <a href="https://subastafutbolera.com/soy-nuevo/" target="blank">Haz click aquí para saber más.</a></p> ${textByRoomType}`,
        showConfirmButton: false,
        confirmButtonText: "COMPRAR LLAVES",
        confirmButtonColor: "#16A64A",
        showCancelButton: true,
        cancelButtonText: "OK",
        cancelButtonColor: "#16A64A",
        position: "top",
      }).then((result) => {
        if (result.value) {
          window.open("https://subastafutbolera.com/shop", "_blank");
        }
      });
    } else {
      if (this.state.type === "5") {
        Swal.fire({
          //imageUrl: ayudaSalaSubasta,
          html: `<p><strong>Bienvenido a Subasta Futbolera</strong>, te encuentras en una sala pública, puedes escoger un marcador y participar en nuestro ranking público.</p><p>Registrate, crea tu sala privada y juega con tus amigos en las modalidades subasta, o clásica </p> <a href="https://subastafutbolera.com/soy-nuevo/" target="blank">Haz click aquí para saber más.</a></p> ${textByRoomType}`,
          showConfirmButton: true,
          confirmButtonText: "REGÍSTRATE",
          confirmButtonColor: "#16A64A",
          showCancelButton: true,
          cancelButtonText: "OK",
          cancelButtonColor: "#16A64A",
          position: "top",
        }).then((result) => {
          if (result.value) {
            window.open("https://app.subastafutbolera.com/signup", "_blank");
          }
        });
      } else {
        Swal.fire({
          //imageUrl: ayudaSalaSubasta,
          html: `<h3>Hola, quizás llegaste acá como invitado o creador de la sala. Bienvenido.</h3><p>Subasta Futbolera es un sitio web creado para los fanáticos del fútbol, los pronósticos y las estrategias.</p><p>El tablero digital permite jugar con los marcadores de partidos en 3 diferentes modalidades. Clásica, Subasta, Pública. <a href="https://subastafutbolera.com/soy-nuevo/" target="blank">Haz click aquí para saber más.</a></p> ${textByRoomType}`,
          showConfirmButton: false,
          confirmButtonText: "COMPRAR LLAVES",
          confirmButtonColor: "#16A64A",
          showCancelButton: true,
          cancelButtonText: "OK",
          cancelButtonColor: "#16A64A",
          position: "top",
        }).then((result) => {
          if (result.value) {
            window.open("https://subastafutbolera.com/shop", "_blank");
          }
        });
      }
    }
  }

  makeRoomReport() {
    let roomWinnerName = "";
    let report = "";
    let roomWinnerId = "";

    let playersList = this.state.roomOffers;
    delete playersList.isPlayer;
    delete playersList.isOwner;
    let playersListSorted = {};
    Object.keys(playersList).forEach(function (key) {
      playersListSorted[key] = playersList[key];
    });
    console.log(playersListSorted);
    let scoresListTitles = Object.keys(playersListSorted);
    let titlesA = scoresListTitles.map((score) => score.replace("L", "", "gi"));
    let titlesArray = titlesA.map((score) => score.replace("V", " - ", "gi"));
    console.log(titlesArray);
    let namesArray = [];
    Object.keys(playersListSorted).forEach(function (key) {
      console.log(key, playersListSorted[key].player);
      namesArray.push(playersListSorted[key].player);
    });
    console.log(namesArray);
    let offersArray = [];
    Object.keys(playersListSorted).forEach(function (key) {
      console.log(key, playersListSorted[key].offer);
      offersArray.push(playersListSorted[key].offer);
    });
    console.log(offersArray);

    const arrayOfPlayersList = Object.keys(playersListSorted).map((key) => {
      return playersListSorted[key];
    });
    console.log(arrayOfPlayersList);
    if (roomWinnerId != 0) {
      console.log(roomWinnerId);

      let winnerOffers = arrayOfPlayersList.filter((el) => {
        return el.playerId === roomWinnerId;
      });
      console.log(winnerOffers);

      let totalOfferWinner = 0;
      for (let i = 0; i < winnerOffers.length; i++) {
        console.log(winnerOffers[i].offer);
        let offerNumber = parseInt(winnerOffers[i].offer);
        console.log(offerNumber);
        totalOfferWinner += offerNumber;
      }

      console.log(`${roomWinnerName} ofertó un total de: ${totalOfferWinner}`);
    }

    // get records by player
    console.log(arrayOfPlayersList);
    const filteredPlayerList = arrayOfPlayersList.reduce((acc, current) => {
      const x = acc.find((item) => item.playerId === current.playerId);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    console.log(filteredPlayerList);
    for (let j = 0; j < filteredPlayerList.length; j++) {
      console.log(filteredPlayerList[j].playerId);
      let postData = {
        client_user_id: filteredPlayerList[j].playerId,
        room_id: this.state.roomId,
      };
      if (postData) {
        PostData("getRecordsByPlayer", postData).then((result) => {
          console.log(result.recordsByPlayer);
          let recordsByPlayer = result.recordsByPlayer;

          let recordByPlayerOffersSubtotal = 0;
          for (let k = 0; k < recordsByPlayer.length; k++) {
            console.log(recordsByPlayer[k].my_bet);
            recordByPlayerOffersSubtotal += parseInt(recordsByPlayer[k].my_bet);
            report += `<div class="report-text-container"><div class="report-text">${
              recordsByPlayer[k].name
            }</div><div class="report-text">${
              recordsByPlayer[k].score
            }</div><div class="report-text">${formatter.format(
              recordsByPlayer[k].my_bet
            )}</div></div>`;
          }
          report += `<div class="subtotal">Subtotal: ${formatter.format(
            recordByPlayerOffersSubtotal
          )}</div><hr>`;
          console.log(report);
          console.log(`el subtotal es: ${recordByPlayerOffersSubtotal}`);
          Swal.fire({
            title: "Reporte de la Sala",
            html: report,
            showConfirmButton: true,
            confirmButtonText: "Entendido",
            confirmButtonColor: "#16A64A",
            position: "top",
          });
        });
      }
    }
  }

  openRoomReport() {
    if (this.state.status === "4") {
      this.setState({ showRoomReport: true });
    } else {
      if (this.state.status === "1") {
        Swal.fire({
          title: "Juega y gana!",
          text: "El partido no ha empezado, puedes seguir ofertando, cuando termine, aquí veras los resultados.",
          showConfirmButton: true,
          confirmButtonText: "Entendido",
          confirmButtonColor: "#16A64A",
          position: "top",
        });
      }
    }
  }

  unBlockRoom() {
    console.clear();
    console.log(this.state.userId);
    let postData = { user_id: this.state.userId };

    PostData("getKeys", postData).then((res) => {
      this.setState({ credits: res.keys }, () =>
        console.log(this.state.credits)
      );
      if (res.keys === 0) {
        Swal.fire({
          title: "Desbloquea tu sala!",
          text: "Compra una llave para quitar límite de jugadores y obtener más funcionalidades plus de la sala.",
          showConfirmButton: true,
          confirmButtonText: "COMPRAR LLAVES",
          confirmButtonColor: "#16A64A",
          position: "top",
        }).then((result) => {
          if (result.value) {
            window.open("https://subastafutbolera.com/shop", "_blank");
          }
        });
      } else {
        let offerLimitText = "0";
        if (this.state.type === "1") {
          offerLimitText = "4";
        }
        if (this.state.type === "2") {
          offerLimitText = "2";
        }
        Swal.fire({
          html: `<h3>Tienes ${res.keys} llaves disponibles</h3><h5>Funciones gratis</h5><ul class="subasta-help"><li>Máximo 6 jugadores</li> <li>Máximo ${offerLimitText} marcadores por jugador</li><li>Link para invitar amigos</li><li>Dinámica según modalidad</li><li>Reporte estándar con resumen de cuentas</li></ul><h5>Funciones plus</h5><h6>Se activan cuando usas una llave digital que puedes comprar en nuestra tienda</h6><ul class="subasta-help"><li>Sin Límite de Jugadores</li><li>Sin límite de marcadores por jugador</li><li>Reporte mejorado, cuentas por jugador</li></ul>`,
          showConfirmButton: true,
          confirmButtonText: "USAR 1 LLAVE",
          confirmButtonColor: "#16A64A",
          cancelButtonText: "CANCELAR",
          position: "top",
        }).then((result) => {
          if (result.value) {
            let postData = { user_id: this.state.userId };
            PostData("useKey", postData).then((res) => {
              if (res.res === 1) {
                let data = JSON.parse(localStorage.getItem("userData"));
                console.log(data.credits);
                //data.userData.credits = parseInt(data.userData.credits) - 1;
                localStorage.setItem("userData", JSON.stringify(data));
                let roomSku = { sku: this.state.sku };
                PostData("makeRoomPremium", roomSku).then((res) => {
                  if (res.res === 1) {
                    this.setState({ premium: true }, () =>
                      console.log(this.state.premium)
                    );
                    Swal.fire({
                      title: "¡Sala desbloqueada!",
                      text: "Ahora puedes ver reportes personalizados e invitar a todos los que quieras a tu sala Premium!. ¡Felicitaciones!",
                      showConfirmButton: true,
                      confirmButtonText: "ENTENDIDO",
                      confirmButtonColor: "#16A64A",
                      position: "top",
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  render() {
    let showReport;
    let viewCreateRoomsMenu = this.state.viewCreateRoomsMenu;
    if (this.state.showRoomReport === true) {
      showReport = (
        <RoomReport
          roomId={this.state.roomId}
          roomStatus={this.state.status}
          roomOffers={this.state.roomOffers}
          localTeamName={this.state.localTeamName}
          matchScoreLocal={this.state.matchScoreLocal}
          visitorTeamName={this.state.visitorTeamName}
          matchScoreVisitor={this.state.matchScoreVisitor}
          sku={this.state.sku}
          premium={this.state.premium}
          type={this.state.type}
          matchId={this.state.matchId}
        />
      );
    }
    let roomTotal = this.calculateRoomTotalOffer();
    let roomTotalFormated = formatter.format(roomTotal);
    let makeOffer;
    if (this.state.makingOffer) {
      console.log("score offer for = ", this.state.scoreOfferFor);
      let scoreId = this.state.scoreOfferFor;
      console.log("scoreOfferData para pasar:", this.state.roomOffers[scoreId]);
      let scoreOfferData;
      if (this.state.roomOffers[scoreId] !== undefined) {
        scoreOfferData = this.state.roomOffers[scoreId];
      } else {
        scoreOfferData = {
          offer: 0,
          playerId: "none",
          player: "Marcador Libre",
        };
      }
      makeOffer = (
        <ScoreOffering
          sku={this.state.sku}
          scoreId={scoreId}
          localTeam={this.state.localTeamName}
          visitorTeam={this.state.visitorTeamName}
          unity={this.state.unity}
          roomId={this.state.roomId}
          scoreOfferData={scoreOfferData}
          type_room={this.state.type}
          matchId={this.state.matchId}
          name={this.state.name}
          email={this.state.email}
        />
      );
    }
    let previewRoomActive;
    if (this.state.previewRoom) {
      previewRoomActive = (
        <div className="room-preview-container">
          <div className="room-preview-message-container">
            <div className="room-preview-text">
              <p>¡Te estamos esperando!, ingresa para poder jugar.</p>
            </div>
            <div>
              <Link className="btn btn-primary centered" to={"/"}>
                Ingresar
              </Link>
            </div>
          </div>
        </div>
      );
    }
    let roomIsFull;
    if (this.state.roomIsFull) {
      roomIsFull = (
        <div className="room-preview-container">
          <div className="room-preview-message-container">
            <div className="room-preview-text">
              <p>
                ¡Oh!, Este partido es tan bueno que la sala se llenó en un
                segundo.
              </p>
              <p>
                Compra o usa una llave y desbloquea la cantidad de jugadores y
                más funcionalidades avanzadas
              </p>
            </div>

            <a
              href="https://www.subastafutbolera.com/shop"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="btn btn-primary centered">Comprar Llaves</div>
            </a>
          </div>
        </div>
      );
    }
    let unableToOffer;
    if (this.state.unableToOffer && this.state.type === "1") {
      unableToOffer = (
        <div className="room-preview-container">
          <div className="room-preview-message-container">
            <div className="room-preview-text">
              <p>¡Ohh!, Ya no puedes hacer más ofertas.</p>
              <p>
                No te pongas triste, si alguien te quita una oferta podrás
                volver a ofertar. Recuerda que solo puedes ofertar máximo hasta
                por cinco (5) marcadores distintos en una sala.
              </p>
            </div>
            <div className="button-container">
              <div
                className="btn btn-danger centered col"
                onClick={this.reload}
              >
                ENTENDIDO
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.unableToOffer && this.state.type === "2") {
      unableToOffer = (
        <div className="room-preview-container">
          <div className="room-preview-message-container">
            <div className="room-preview-text">
              <p>¡Ohh!, Ya no puedes hacer más ofertas.</p>
              <p>Solo puedes ofertar por dos (2) marcadores.</p>
            </div>
            <div className="button-container">
              <div
                className="btn btn-danger centered col"
                onClick={this.reload}
              >
                VOLVER
              </div>
            </div>
          </div>
        </div>
      );
    }
    let showIconReports;
    if (this.state.status === "4") {
      showIconReports = (
        <img src={roomIconReportsAlert} alt="Reportes de la Sala" />
      );
    } else {
      showIconReports = <img src={roomIconReports} alt="Reportes de la Sala" />;
    }
    // if (this.state.redirectToReferrer) {
    //   return <Redirect to={"/"} />;
    // }
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          <div className="subasta-room-wrap-container">
            {unableToOffer}
            {makeOffer}
            {previewRoomActive}
            {roomIsFull}
            <NavSup />
            <AddSystem />
            <div className="subasta-room-screen-container">
              <div className="subasta-room-btn-container mobile">
                <div className="btn btn-danger-small centered">
                  <Link to={"/"}>
                    <img src={roomIconHome} alt="Volver a Inicio" />
                  </Link>
                </div>
              </div>
              <h1>{this.state.roomName}</h1>
              <h2>{this.state.matchName}</h2>
              <div className="subasta-room-screen-options-menu-container">
                <div
                  className="subasta-room-screen-options-menu-item"
                  onClick={this.showSubastaRoomHelp}
                >
                  {this.state.type === "5" && <span>Pública</span>}
                  {this.state.type === "2" && <span>Clásica</span>}
                  {this.state.type === "1" && <span>Subasta</span>}
                </div>
                {/* {this.state.type !== "5" && (
                <>
                  {this.state.premium ? (
                    <div className="subasta-room-screen-options-menu-item item-plus">
                      <span className="subasta-room-screen-options-text">
                        Plus
                      </span>
                    </div>
                  ) : (
                    <div className="subasta-room-screen-options-menu-item">
                      <span
                        className="subasta-room-screen-options-text"
                        onClick={this.unBlockRoom}
                      >
                        Gratis
                      </span>
                    </div>
                  )}
                </>
              )} */}

                <div
                  className="subasta-room-screen-options-menu-item"
                  onClick={this.copyRoomLink}
                >
                  <img src={roomIconShare} alt="Invita a tus amigos" />
                </div>
              </div>
              <div className="subasta-room-screen-grid-container">
                <div className="visitorTeam">
                  <div className="outer">
                    <div className="inner rotate">
                      GOLES {this.state.visitorTeamName}
                    </div>
                  </div>
                </div>
                <div className="localTeam">
                  GOLES {this.state.localTeamName}
                </div>
                <div className="score-grid-container">
                  <div className="subasta-room-screen-score-grid">
                    <div className="helpIcon" onClick={this.seePlayersNameList}>
                      <img src={roomIconUsers} alt="Ayuda" />
                    </div>
                    <div className="scoreTitleX scoreTitleX0">0</div>
                    <div className="scoreTitleX scoreTitleX1">1</div>
                    <div className="scoreTitleX scoreTitleX2">2</div>
                    <div className="scoreTitleX scoreTitleX3">3</div>
                    <div className="scoreTitleX scoreTitleX4">4</div>
                    <div className="scoreTitleX scoreTitleX5">5</div>
                    <div className="scoreTitleY scoreTitleY0">0</div>
                    <div className="scoreTitleY scoreTitleY1">1</div>
                    <div className="scoreTitleY scoreTitleY2">2</div>
                    <div className="scoreTitleY scoreTitleY3">3</div>
                    <div className="scoreTitleY scoreTitleY4">4</div>
                    <div className="scoreTitleY scoreTitleY5">5</div>
                    {this.drawScoreGrid()}
                  </div>
                </div>
              </div>
              <div className="subasta-room-screen-total-container">
                {this.state.type === "5" ? (
                  <div className="subasta-room-screen-total-value">
                    Total Ofertas:{" "}
                    <span className="subasta-room-screen-total-value-number">
                      {this.state.offersByScore.length}
                    </span>
                  </div>
                ) : (
                  <div className="subasta-room-screen-total-wrap">
                    <div className="subasta-room-screen-total-value">
                      Total Sala:{" "}
                      <span className="subasta-room-screen-total-value-number">
                        {roomTotalFormated}
                      </span>
                    </div>
                    {this.state.type === "1" && (
                      <div className="subasta-room-screen-total-value">
                        Unidad de Aumento:{" "}
                        <span className="subasta-room-screen-total-value-number">
                          {this.state.unity}
                        </span>
                      </div>
                    )}
                    {this.state.type === "2" && (
                      <div className="subasta-room-screen-total-value">
                        Valor del marcador:{" "}
                        <span className="subasta-room-screen-total-value-number">
                          {this.state.unity}
                        </span>
                      </div>
                    )}
                  </div>
                )}

                <div
                  className="subasta-room-screen-options-menu-item"
                  onClick={this.openRoomReport}
                >
                  {showIconReports}
                  {showReport}
                </div>
              </div>
              <h3 className="room-matchDate-title">
                Hora de cierre de la sala:
              </h3>
              <h3 className="room-matchDate-date">
                {/* {this.state.fullMatchDate.toLocaleString("es-CO", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                timeZoneName: "short",
              })} */}
                {this.state.browserDateTimeString}
              </h3>
              {viewCreateRoomsMenu && (
                <div className="subasta-createRoomsMenu-container">
                  <CreateRoomMenuComponent isAdmin={this.state.isAdmin} />
                </div>
              )}
            </div>

            {/* <div className="subasta-createRoomsMenu-container">
              <CreateRoomMenuComponent isAdmin={this.state.isAdmin} />
            </div> */}
            <NavInf isAdmin={true} />
          </div>
        </React.Fragment>
      );
    }
  }
}

export default Room;
