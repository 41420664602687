import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./RoomCard.css";
import { PostData } from "../../services/PostData";
import Swal from "sweetalert2";

import subastaRoomIcon from "../../images/subastaRoomIcon.png";
import classicRoomIcon from "../../images/classicRoomIcon.png";
import publicRoomIcon from "../../images/publicRoomIcon.svg";
import tomyPopUp from "../../images/tomyPopUp.png";

class RoomCard extends Component {
  constructor(props) {
    super(props);
    //console.log('datos ficha:',this.props.roomData);
    const roomData = { ...this.props.roomData };
    this.state = { ...roomData };
    console.log("roomdata: ", this.state.roomData);
    this.getMatchData = this.getMatchData.bind(this);
    this.copyRoomLink = this.copyRoomLink.bind(this);
    this.setMatchStatusText = this.setMatchStatusText.bind(this);
  }

  componentDidMount() {
    this.getMatchData();
  }

  getMatchData() {
    let postData = {
      match_id: this.state.match_id,
    };
    PostData("getMatchData", postData).then((result) => {
      let responseJson = result;
      let matchData = responseJson.matchData[0];
      this.setState(
        {
          matchName: matchData.name,
          matchDate: matchData.date,
          matchHour: matchData.hour,
        },
        () => {
          this.setMatchStatusText();
        }
      );
      this.setState({ loading: false });
    });
  }

  setMatchStatusText() {
    if (this.state.status === "1") {
      this.setState({ matchStatusText: "Activa" }, () => {
        //console.log(this.state.matchStatusText);
      });
    }
    if (this.state.status === "4") {
      this.setState({ matchStatusText: "Cerrada" }, () => {
        //console.log(this.state.matchStatusText);
      });
    }
  }

  copyRoomLink() {
    let copyText = `¡Hey!, te invito a jugar ${this.state.matchName} en Subasta Futbolera. Ingresa a: https://app.subastafutbolera.com/rooms/${this.state.sku} y libera la bestia!. Conoce más en https://subastafutbolera.com`;
    let tempInput = document.createElement("INPUT");
    tempInput.setAttribute("type", "text");
    tempInput.setAttribute("id", "textToCopy");
    tempInput.setAttribute("value", copyText);
    document.body.appendChild(tempInput);
    let element = document.getElementById("textToCopy");
    console.log(element);
    element.select();
    document.execCommand("copy");
    Swal.fire({
      imageUrl: tomyPopUp,
      imageWidth: 85,
      imageHeight: 130,
      title: "Listo!",
      text: `El enlace a la sala está copiado, compártelo en tus redes favoritas`,
      showConfirmButton: true,
      confirmButtonText: "Entendido",
      confirmButtonColor: "#16A64A",
      position: "top",
    });
  }

  render() {
    //const roomData = {...this.props.roomData};

    return (
      <div className="subasta-room-container">
        <div className="subasta-room-header">
          {this.state.type_room === "1" && (
            <>
              <div className="subasta-room-type-icon">
                <img src={subastaRoomIcon} alt="Sala tipo Subasta" />
              </div>
              <div className="subasta-room-type-title">SUBASTA</div>
            </>
          )}
          {this.state.type_room === "2" && (
            <>
              <div className="subasta-room-type-icon">
                <img src={classicRoomIcon} alt="Sala tipo Subasta" />
              </div>
              <div className="subasta-room-type-title">CLÁSICA</div>
            </>
          )}
          {this.state.type_room === "5" && (
            <>
              <div className="subasta-room-type-icon">
                <img src={publicRoomIcon} alt="Sala tipo Subasta" />
              </div>
              <div className="subasta-room-type-title">PÚBLICA</div>
            </>
          )}
          <div className="subasta-room-status">
            {this.state.matchStatusText}
          </div>
        </div>
        <div className="subasta-room-match-name-container">
          <div className="subasta-room-match-name">{this.state.name}</div>
        </div>
        <div className="subasta-room-data-container">
          <div className="subasta-romm-data-row">
            <div className="subasta-room-data-title">PARTIDO:</div>
            <div className="subasta-room-data-field">
              {this.state.matchName}
            </div>
          </div>
          <div className="subasta-romm-data-row">
            <div className="subasta-room-data-title">FECHA:</div>
            <div className="subasta-room-data-field">
              {this.state.matchDate} - {this.state.matchHour}
            </div>
          </div>
          <div className="subasta-romm-data-row">
            <div className="subasta-room-btn-container">
              <Link
                to={"/Rooms/" + this.state.sku}
                className="btn btn-primary-small"
              >
                VER SALA
              </Link>
            </div>
            <div className="subasta-room-btn-container">
              <div
                className="btn btn-primary-small"
                onClick={this.copyRoomLink}
              >
                COMPARTIR
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RoomCard;
