import React, { Component } from "react";

import "./styles/custom.css";

import Routes from "./Routes";
// import Header from './components/Header/Header';
// import Footer from './components/Footer/Footer';
//import MobileHeader from './components/MobileHeader/MobileHeader';

class App extends Component {
  constructor() {
    super();
    this.state = {
      appName: "SUBASTA FUTBOLERA",
      home: false,
    };
  }

  render() {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    console.log("Complete Cache Cleared");
    return (
      <div className="off-canvas-wrapper">
        <div className="off-canvas-wrapper-inner" data-off-canvas-wrapper>
          <div className="off-canvas-content" data-off-canvas-content>
            <Routes name={this.state.appName} />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
