import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { PostData } from "../../services/PostData";
import Swal from "sweetalert2";
import RoomSubastaFutbolera from "../../components/RoomSubastaFutbolera/RoomSubastaFutbolera";

import "./CreateClassicRoom.css";

import createClassicIconWhite from "../../images/classicRoomWhiteIcon.png";

import subastaHelpIcon from "../../images/subastaHelpIcon.png";
import tomyPopUp from "../../images/tomyPopUp.png";

class CreateClassicRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomName: "",
      championshipsList: null,
      roomData: "",
      championShip: null,
      championShipIdFound: false,
      championShipID: "",
      matchList: null,
      match: null,
      matchIdFound: false,
      matchID: "",
      matchName: "",
      customMatchName: "",
      existentMatchData: [],
      horaSubasta: null,
      minutoSubasta: null,
      unidadAumento: "0",
      matchDate: "",
      matchDateDB: "",
      matchHourDB: "",
      subastaDate: "",
      subastaDateDB: "",
      subastaHourDB: "",
      customMatchDate: "",
      teamsList: [],
      localTeam: "0",
      localTeamName: "",
      visitorTeam: "0",
      visitorTeamName: "",
      subastaRoomCreated: false,
      initialChampionShipName: "0",
      initialMatchName: "0",
      initialLocalTeam: "Equipo local...",
      initialVisitorTeam: "Equipo visitante...",
      sku: "",
      goback: false,
    };
    this.getChampionShipList = this.getChampionShipList.bind(this);
    this.getMatchList = this.getMatchList.bind(this);
    this.onChange = this.onChange.bind(this);
    this.setChampionShipId = this.setChampionShipId.bind(this);
    this.getExistentMatchData = this.getExistentMatchData.bind(this);
    this.setHoraSubasta = this.setHoraSubasta.bind(this);
    this.setMinutoSubasta = this.setMinutoSubasta.bind(this);
    this.setRoomName = this.setRoomName.bind(this);
    this.validate = this.validate.bind(this);
    this.setUnidadAumento = this.setUnidadAumento.bind(this);
    this.getTeamsList = this.getTeamsList.bind(this);
    this.setCustomMatchDate = this.setCustomMatchDate.bind(this);
    this.setHoraPartido = this.setHoraPartido.bind(this);
    this.setMinutoPartido = this.setMinutoPartido.bind(this);
    this.setFechaLimiteCustomMatch = this.setFechaLimiteCustomMatch.bind(this);
    this.setHoraSubasta = this.setHoraSubasta.bind(this);
    this.setLocalTeam = this.setLocalTeam.bind(this);
    this.setVisitorTeam = this.setVisitorTeam.bind(this);
    this.setMatchId = this.setMatchId.bind(this);
    this.popupUnidadAumento = this.popupUnidadAumento.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  resetForm() {
    console.log("se reinician los valores de los campos en el formulario");
    this.setState({
      setMatchId: this.state.initialMatchName,
      localTeam: "0",
      visitorTeam: "0",
      matchID: "",
      unidadAumento: "0",
    });
  }

  componentDidMount() {
    //let userData = JSON.parse(localStorage.getItem("userData"));
    //console.log(userData.userData.id);
    window.history.pushState("home", "subasta", "/home");
    this.getChampionShipList();
    window.onpopstate = function (event) {
      Swal.fire({
        text: "¿Estás seguro?. Si sales la sala no será creada.",
        showCancelButton: true,
        cancelButtonText: "Continuar",
        cancelButtonColor: "#16A64A",
        showConfirmButton: true,
        confirmButtonText: "Salir",
        confirmButtonColor: "#e94148",
        reverseButtons: true,
        icon: "warning",
        position: "top",
      }).then((result) => {
        if (result.value) {
          console.log("recarga estado");
          window.history.go();
        }
      });
    };
    // window.addEventListener("popstate", e => {

    //   e.preventDefault();
    //   window.history.go();
    //   this.setState({ goback: true });
    //   console.log("goback = " + this.state.goback);
    // });
  }

  setRoomName(e) {
    this.setState({ roomName: e.target.value });
  }

  onChange(e) {
    this.setState({ roomData: e.target.value });
  }

  getChampionShipList() {
    let postData = {};
    PostData("getChampionShipList", postData).then((result) => {
      let responseJson = result;
      this.setState({ championshipsList: responseJson.championShipData });
    });
  }

  setChampionShipId = (championShip) => {
    this.setState({ championShip }, () => {
      this.setState(
        {
          championShipID: this.refs.championShipValue.value,
          championShipIdFound: true,
        },
        () => {
          this.getMatchList();
          this.resetForm();
        }
      );
    });
  };

  getMatchList() {
    let postData = { match_id: this.refs.championShipValue.value };
    PostData("getMatchList", postData).then((result) => {
      let responseJson = result;
      this.setState({ matchList: responseJson.matchData });
    });
  }

  setMatchId(e) {
    var index = e.target.selectedIndex;
    this.setState(
      {
        matchID: e.target.value,
        matchName: e.target[index].text,
        unidadAumento: "0",
        matchIdFound: true,
      },
      () => {
        if (this.state.matchID === "1010") {
          this.getTeamsList();
        } else {
          this.getExistentMatchData();
        }
      }
    );
  }

  getExistentMatchData() {
    let postData = { match_id: this.state.matchID };
    PostData("getExistentMatchData", postData).then((result) => {
      let responseJson = result;
      this.setState({ existentMatchData: responseJson.existentMatchData });
      let matchDataObj = this.state.existentMatchData[0];
      console.log(this.state.existentMatchData[0]);
      this.setState(
        {
          existentMatchData: matchDataObj,
          matchDateDB: this.state.existentMatchData[0].date,
          matchHourDB: this.state.existentMatchData[0].hour,
        },
        () => {
          var datoPartidoFecha = this.state.existentMatchData.date;
          let dateParts = datoPartidoFecha.split("-");
          let fechaPartidoCompleta = new Date(
            dateParts[0],
            dateParts[1] - 1,
            dateParts[2].substr(0, 2)
          );
          let datoPartidoHora = this.state.existentMatchData.hour;
          let hourParts = datoPartidoHora.split(":");
          fechaPartidoCompleta.setHours(
            fechaPartidoCompleta.getHours() + hourParts[0]
          );
          fechaPartidoCompleta.setMinutes(
            fechaPartidoCompleta.getMinutes() + hourParts[1]
          );
          this.setState(
            {
              matchDate: fechaPartidoCompleta,
            },
            () => {
              var fechaLimiteSubasta = new Date(this.state.matchDate);
              fechaLimiteSubasta.setMinutes(
                fechaLimiteSubasta.getMinutes() - 30
              );

              this.setState(
                {
                  subastaDate: fechaLimiteSubasta,
                },
                () => {
                  let dateSubasta = new Date(this.state.subastaDate);
                  let horaSubasta = dateSubasta.getHours();
                  if (horaSubasta < 10) {
                    horaSubasta = "0" + horaSubasta;
                  }
                  let minutosSubasta = dateSubasta.getMinutes();
                  if (minutosSubasta < 10) {
                    minutosSubasta = "0" + minutosSubasta;
                  }
                  this.setState({
                    horaSubasta: horaSubasta + ":" + minutosSubasta,
                  });
                }
              );
            }
          );
        }
      );
    });
  }

  setHoraSubasta = (horaSubasta) => {
    this.setState({ horaSubasta }, () => {
      this.setState({ horaSubasta: this.state.horaSubasta.value });
    });
  };

  setMinutoSubasta = (minutoSubasta) => {
    this.setState(
      {
        minutoSubasta,
      },
      () => {
        this.setState({ minutoSubasta: this.state.minutoSubasta.value });
      }
    );
  };

  setCustomMatchDate(e) {
    console.log("guardamos la fecha del partido personalizado");
    console.log(e.target.value);
    var rawDate = e.target.value.replace(/-/g, ",");
    console.log(rawDate);
    var customMatchDate = new Date(rawDate);
    e.persist();
    this.setState({ customMatchDate: customMatchDate }, () => {
      console.log(
        `la fecha del partido personalizado es: `,
        this.state.customMatchDate
      );
      var customMatchYear = this.state.customMatchDate.getFullYear();
      console.log(`Este es el año: `, customMatchYear);
      var customMatchMonth = this.state.customMatchDate.getMonth() + 1;
      if (customMatchMonth < 10) {
        customMatchMonth = "0" + customMatchMonth;
      }
      console.log(`Este es el mes: `, customMatchMonth);
      var customMatchDay = this.state.customMatchDate.getDate();
      if (customMatchDay < 10) {
        customMatchDay = "0" + customMatchDay;
      }
      console.log(`Este es el día: `, customMatchDay);
      var customMatchDateDB =
        customMatchYear + "-" + customMatchMonth + "-" + customMatchDay;
      this.setState(
        {
          subastaDateDB: customMatchDateDB,
        },
        () => {
          console.log(
            `Esta es la fecha que guardamos en la base de datos`,
            this.state.subastaDateDB
          );
        }
      );
    });
  }

  setHoraPartido(e) {
    e.persist();
    console.log("guardamos la hora de la subasta");
    console.log(this.state.customMatchDate.getHours());
    console.log(e.target.value);
    var matchYear = this.state.customMatchDate.getFullYear();
    var matchMonth = this.state.customMatchDate.getMonth();
    var matchDay = this.state.customMatchDate.getDate();
    var matchHours = e.target.value;
    var matchMinutes;
    if (this.state.matchHourDB === "") {
      matchMinutes = 0;
    } else {
      console.log(this.state.matchHourDB);
      matchMinutes = this.state.matchHourDB.slice(-2);
    }
    var newHour = new Date(
      matchYear,
      matchMonth,
      matchDay,
      matchHours,
      matchMinutes
    );
    console.log(newHour);
    var normalizedMatchMonth = matchMonth + 1;
    console.log(this.state.customMatchDate);
    var normalizedMatchMinute = this.state.customMatchDate.getMinutes();
    if (normalizedMatchMinute < 10) {
      normalizedMatchMinute = "0" + normalizedMatchMinute;
    }
    this.setState(
      {
        customMatchDate: new Date(newHour),
        matchDateDB: matchYear + "-" + normalizedMatchMonth + "-" + matchDay,
        matchHourDB: matchHours + ":" + normalizedMatchMinute,
      },
      () => {
        console.log("fecha con hora es:" + this.state.customMatchDate);
        this.setFechaLimiteCustomMatch();
      }
    );
    console.log("fecha con hora es:" + this.state.customMatchDate);
  }

  setMinutoPartido(e) {
    e.persist();
    console.log("guardamos los minutos del partido");
    console.log(this.state.customMatchDate.getMinutes());
    console.log(e.target.value);
    var matchYear = this.state.customMatchDate.getFullYear();
    var matchMonth = this.state.customMatchDate.getMonth();
    var matchDay = this.state.customMatchDate.getDate();
    var matchHours = this.state.customMatchDate.getHours();
    var newMinute = new Date(
      matchYear,
      matchMonth,
      matchDay,
      matchHours,
      e.target.value
    );
    var normalizedMatchMonth = matchMonth + 1;
    console.log(newMinute);
    var normalizedMatchMinute = newMinute.getMinutes();
    if (normalizedMatchMinute < 10) {
      normalizedMatchMinute = "0" + normalizedMatchMinute;
    }
    this.setState(
      {
        customMatchDate: new Date(newMinute),
        matchDateDB: matchYear + "-" + normalizedMatchMonth + "-" + matchDay,
        matchHourDB: matchHours + ":" + normalizedMatchMinute,
      },
      () => {
        console.log("fecha con minutos es:" + this.state.customMatchDate);
        this.setFechaLimiteCustomMatch();
      }
    );
  }

  setFechaLimiteCustomMatch() {
    var fechaLimiteCustomMatch = new Date(this.state.customMatchDate);
    fechaLimiteCustomMatch.setMinutes(fechaLimiteCustomMatch.getMinutes() - 30);
    var subastaMatchHour = fechaLimiteCustomMatch.getHours();
    if (subastaMatchHour < 10) {
      subastaMatchHour = "0" + subastaMatchHour;
    }
    var subastaMatchMinute = fechaLimiteCustomMatch.getMinutes();
    if (subastaMatchMinute < 10) {
      subastaMatchMinute = "0" + subastaMatchMinute;
    }
    var subastaMatchHourDB = subastaMatchHour + ":" + subastaMatchMinute;
    this.setState({
      subastaDate: fechaLimiteCustomMatch,
      subastaHourDB: subastaMatchHourDB,
    });
  }

  setLocalTeam(e) {
    e.persist();
    if (
      this.state.visitorTeam !== "" &&
      e.target.value === this.state.visitorTeam
    ) {
      Swal.fire({
        text: "Equipos local y visitante no puedenser iguales",
        type: "error",
        showConfirmButton: true,
        confirmButtonText: "OK",
        confirmButtonColor: "#e94148",
        icon: "error",
        position: "top",
      });
      console.log("mismo equipo");
    } else {
      var index = e.target.selectedIndex;
      this.setState({
        localTeam: e.target.value,
        localTeamName: e.target[index].text,
      });
    }
  }

  setVisitorTeam(e) {
    e.persist();
    if (
      this.state.localTeam !== "" &&
      e.target.value === this.state.localTeam
    ) {
      Swal.fire({
        text: "Equipos local y visitante no pueden ser iguales",
        showConfirmButton: true,
        confirmButtonText: "OK",
        confirmButtonColor: "#e94148",
        icon: "error",
        position: "top",
      });
      console.log("mismo equipo");
    } else {
      var index = e.target.selectedIndex;
      this.setState({
        visitorTeam: e.target.value,
        visitorTeamName: e.target[index].text,
      });
    }
  }

  setUnidadAumento(e) {
    this.setState({ unidadAumento: e.target.value });
  }

  getTeamsList() {
    let postData = { championshipId: this.state.championShipID };
    PostData("getTeamsList", postData).then((result) => {
      let responseJson = result;
      this.setState({ teamsList: responseJson.teamsList });
    });
  }

  validate() {
    console.log("validamos los datos del formulario");
    if (this.state.roomName === "") {
      Swal.fire({
        text: "La sala no tiene nombre",
        showConfirmButton: true,
        confirmButtonText: "OK",
        confirmButtonColor: "#e94148",
        icon: "error",
        position: "top",
      });
      console.log("no hay nombre");
    } else if (
      this.state.matchID === "1010" &&
      this.state.localTeam === this.state.visitorTeam
    ) {
      Swal.fire({
        text: "Equipos local y visitante no pueden ser iguales",
        showConfirmButton: true,
        confirmButtonText: "OK",
        confirmButtonColor: "#e94148",
        icon: "error",
        position: "top",
      });
      console.log("mismo equipo");
    } else if (
      this.state.matchID === "1010" &&
      (this.state.localTeam === "0" || this.state.visitorTeam === "0")
    ) {
      Swal.fire({
        text: "Equipos local o visitante inválido",
        showConfirmButton: true,
        confirmButtonText: "OK",
        confirmButtonColor: "#e94148",
        icon: "error",
        position: "top",
      });
      console.log("mismo equipo");
    } else if (this.state.matchID === "") {
      Swal.fire({
        text: "No has seleccionado el partido",
        showConfirmButton: true,
        confirmButtonText: "OK",
        confirmButtonColor: "#e94148",
        icon: "error",
        position: "top",
      });
      console.log("cambio el campeonato y se reinició el partido");
    } else if (
      this.state.matchID === "1010" &&
      this.state.customMatchDate === ""
    ) {
      Swal.fire({
        text: "Debes seleccionar la fecha del partido",
        showConfirmButton: true,
        confirmButtonText: "OK",
        confirmButtonColor: "#e94148",
        icon: "error",
        position: "top",
      });
      console.log("mismo equipo");
    } else if (this.state.unidadAumento === "0") {
      Swal.fire({
        text: "Digita el valor del marcador",
        showConfirmButton: true,
        confirmButtonText: "OK",
        confirmButtonColor: "#e94148",
        icon: "error",
        position: "top",
      });
      console.log("valor del marcados");
    } else {
      var popupMatchName = "";
      if (this.state.matchName === "El partido no está en la lista") {
        popupMatchName =
          this.state.localTeamName + " Vs " + this.state.visitorTeamName;
        this.setState({ customMatchName: popupMatchName });
      } else {
        popupMatchName = this.state.matchName;
      }
      var popupMatchDate = "";
      if (this.state.matchDate === "" || this.state.matchID === "1010") {
        popupMatchDate = this.state.customMatchDate;
      } else {
        popupMatchDate = this.state.matchDate;
      }
      // var popupSubastaDate = this.state.subastaDate;
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const timeOptions = { hour: "2-digit", minute: "2-digit" };
      var unity = Number(this.state.unidadAumento);
      const formatter = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 2,
      });
      let sku = Math.floor(10000 + Math.random() * 90000);
      this.setState({ sku: sku }, () =>
        console.log("SKU generado: " + this.state.sku)
      );
      let hour = this.state.subastaDate.getHours();
      if (hour < 10) {
        hour = "0" + hour;
      }
      let minute = this.state.subastaDate.getMinutes();
      if (minute < 10) {
        minute = "0" + minute;
      }
      let userData = JSON.parse(localStorage.getItem("userData"));
      let userInfo = userData.userData.id;
      console.log(popupMatchDate);
      let date_position = popupMatchDate
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      console.log(date_position);
      Swal.fire({
        title: "¡Confirma que todo esté bien!",
        html:
          "<div><h3>Tu sala se llamará: </h3><spam>" +
          this.state.roomName +
          "</spam></div>" +
          "<div><h3>Partido: </h3><spam>" +
          popupMatchName +
          "</spam></div>" +
          "<div><h3>Fecha del partido: </h3><spam>" +
          popupMatchDate.toLocaleDateString("es-ES", options) +
          " a las " +
          popupMatchDate.toLocaleTimeString("es-ES", timeOptions) +
          "</spam></div>" +
          "<div><h3>Valor del marcador: </h3><spam>" +
          formatter.format(unity) +
          "</spam></div>",
        showConfirmButton: true,
        confirmButtonText: "OK",
        showCancelButton: true,
        cancelButtonText: "volver",
        icon: "info",
        position: "top",
      }).then((result) => {
        if (result.value) {
          if (this.state.matchID === "1010") {
            console.log(
              "Guarda Partido Personalizado con los siguientes datos:"
            );
          } else {
            console.log(
              "Guarda Partido Predeterminado con los siguientes datos:"
            );
            console.log("SKU:", this.state.sku);
            console.log("type_room:", 2);
            console.log("name: ", this.state.roomName);
            console.log("type_match: ", 1);
            console.log("match_id: ", this.state.matchID);
            console.log("unity: ", this.state.unidadAumento);
            console.log("hour: ", hour);
            console.log("minute: ", minute);
            console.log("status: ", 1);
            console.log("client_user_id: ", userInfo);
            console.log("plus: ", false);
            console.log("position: ", 0);
            console.log("date_position:", date_position);
            console.log("created_at: fecha de creación");
            console.log("updated_at: fecha de modificación");
            let roomData = {};
            roomData.sku = this.state.sku;
            roomData.type_room = 2;
            roomData.name = this.state.roomName;
            roomData.type_match = 1;
            roomData.match_id = this.state.matchID;
            roomData.unity = this.state.unidadAumento;
            roomData.hour = hour;
            roomData.minute = minute;
            roomData.status = 1;
            roomData.client_user_id = userInfo;
            roomData.plus = 1;
            roomData.position = 0;
            roomData.date_position = date_position;

            PostData("saveRoom", roomData).then((result) => {
              let responseJson = result;
              let copyText = `¡Hey!, te invito a jugar ${this.state.matchName} en Subasta Futbolera. Ingresa a: https://app.subastafutbolera.com/rooms/${this.state.roomSku} y libera la bestia!. Conoce más en https://subastafutbolera.com`;
              if (responseJson.roomUpdated === 1) {
                Swal.fire({
                  imageUrl: tomyPopUp,
                  imageWidth: 169,
                  imageHeight: 261,
                  title: "Listo!",
                  text: `La sala se ha creado, desde allí podrás invitar a tus amigos y competir por los marcadores.`,
                  showConfirmButton: true,
                  confirmButtonText: "Ir a la sala",
                  confirmButtonColor: "#16A64A",
                  position: "top",
                }).then((result) => {
                  if (result.value) {
                    window.location.href = `/rooms/${this.state.sku}`;
                  } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                  ) {
                    let tempInput = document.createElement("INPUT");
                    tempInput.setAttribute("type", "text");
                    tempInput.setAttribute("id", "textToCopy");
                    tempInput.setAttribute("value", copyText);
                    document.body.appendChild(tempInput);
                    let element = document.getElementById("textToCopy");
                    console.log(element);
                    element.select();
                    document.execCommand("copy");
                    window.location.href = `/rooms/${this.state.sku}`;
                  }
                });
              } else {
                alert(result.error);
              }
            });
          }
          //console.log("Guarda en la base de datos");
          //this.setState({ subastaRoomCreated: true }, () => {
          //console.log("abre la sala subasta creada o va al home");
          //});
        }
      });
    }
  }

  popupUnidadAumento() {
    Swal.fire({
      imageUrl: tomyPopUp,
      imageWidth: 109,
      imageHeight: 201,
      title: "Valor del marcador",
      text: "Es el valor único para escoger un marcador que no tenga dueño.",
      showConfirmButton: true,
      confirmButtonText: "Entendido",
      confirmButtonColor: "#16A64A",
      position: "top",
    });
  }

  render() {
    if (this.state.goback) {
      return <Redirect to={"/login"} />;
    }
    if (this.state.subastaRoomCreated) {
      return (
        <React.Fragment>
          <RoomSubastaFutbolera />
        </React.Fragment>
      );
    }
    let selectChampionshipsList, optionsItems;
    if (this.state.championshipsList !== null) {
      selectChampionshipsList = this.state.championshipsList;
      optionsItems = selectChampionshipsList.map((championShip) => (
        <option value={championShip.value} key={championShip.value}>
          {championShip.label}
        </option>
      ));
    }

    let selectMatchList, matchItems;
    if (this.state.matchList !== null) {
      selectMatchList = this.state.matchList;
      console.log(selectMatchList);
      matchItems = selectMatchList.map((match) => (
        <option value={match.id} key={match.name}>
          {match.name}
        </option>
      ));
    }

    let selectTeamsList, teamItems;
    if (this.state.teamsList !== null) {
      selectTeamsList = this.state.teamsList;
      teamItems = selectTeamsList.map((team) => (
        <option value={team.value} key={team.value}>
          {team.label}
        </option>
      ));
    }

    let matchListSelectForm;
    if (this.state.championShipIdFound) {
      matchListSelectForm = (
        <React.Fragment>
          <label htmlFor="">Partido:</label>
          <select
            ref="matchValue"
            onChange={this.setMatchId}
            defaultValue={this.state.initialMatchName}
          >
            <option>Selecciona el partido...</option>
            {matchItems}
            {/*<option value="1010">El partido no está en la lista</option>*/}
          </select>
        </React.Fragment>
      );
    }
    let horaSubasta;
    let customMatchForm;
    let existentMatchData;
    if (this.state.matchID === "1010") {
      customMatchForm = (
        <React.Fragment>
          <div className="create-room-description">
            Ingresa los datos del partido personalizado
          </div>
          <div className="create-room-match-data-block">
            <div className="create-room-match-data-title">
              Selecciona los equipos:
            </div>
            <div className="create-room-match-data-time-container">
              <div className="create-room-match-data-hour-container">
                <select
                  ref="localTeam"
                  onChange={this.setLocalTeam}
                  defaultValue={this.state.initialLocalTeam}
                >
                  <option disabled selected>
                    Equipo Local
                  </option>
                  {teamItems}
                </select>
              </div>
              <div className="create-room-match-data-separator-container">
                Vs
              </div>
              <div className="create-room-match-data-hour-container">
                <select
                  ref="visitantTeam"
                  onChange={this.setVisitorTeam}
                  defaultValue={this.state.initialVisitorTeam}
                >
                  <option disabled selected>
                    Equipo Visitante
                  </option>
                  {teamItems}
                </select>
              </div>
            </div>
          </div>
          <div className="create-room-match-data-block">
            <div className="create-room-match-data-title">
              FECHA DEL PARTIDO:
            </div>
            <input
              type="date"
              name="Fecha del Partido"
              required
              onChange={this.setCustomMatchDate}
            />

            <div className="create-room-match-data-title">
              HORA DEL PARTIDO:
            </div>
            <div className="create-room-match-data-time-container">
              <div className="create-room-match-data-hour-container">
                <select ref="horaSubasta" onChange={this.setHoraPartido}>
                  <option disabled selected>
                    Hora:
                  </option>
                  <option value={23}>23</option>
                  <option value={22}>22</option>
                  <option value={21}>21</option>
                  <option value={20}>20</option>
                  <option value={19}>19</option>
                  <option value={18}>18</option>
                  <option value={17}>17</option>
                  <option value={16}>16</option>
                  <option value={15}>15</option>
                  <option value={14}>14</option>
                  <option value={13}>13</option>
                  <option value={12}>12</option>
                  <option value={11}>11</option>
                  <option value={10}>10</option>
                  <option value={9}>09</option>
                  <option value={8}>08</option>
                  <option value={7}>07</option>
                  <option value={6}>06</option>
                  <option value={5}>05</option>
                  <option value={4}>04</option>
                  <option value={3}>03</option>
                  <option value={2}>02</option>
                  <option value={1}>01</option>
                  <option value={0}>00</option>
                </select>
              </div>
              <div className="create-room-match-data-separator-container">
                :
              </div>
              <div className="create-room-match-data-hour-container">
                <select ref="minutosSubasta" onChange={this.setMinutoPartido}>
                  <option disabled selected>
                    Minuto:
                  </option>
                  <option value="00">00</option>
                  <option value="05">05</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                  <option value="35">35</option>
                  <option value="40">40</option>
                  <option value="45">45</option>
                  <option value="50">50</option>
                  <option value="55">55</option>
                </select>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
      if (this.state.subastaDate !== "") {
        console.log("aquí vemos la hora de la subasta: ");
        let customMatchHour = this.state.subastaDate.getHours();
        let customMatchMinute = this.state.subastaDate.getMinutes();
        let unidadAumentoPlaceholder = this.state.unidadAumento;
        if (customMatchHour < 10) {
          customMatchHour = "0" + customMatchHour;
        }
        if (customMatchMinute < 10) {
          customMatchMinute = "0" + customMatchMinute;
        }
        horaSubasta = (
          <React.Fragment>
            <div className="create-room-match-data-time-container">
              <div className="unit-container" onClick={this.popupUnidadAumento}>
                <div className="helpIcon-container">
                  <label htmlFor="">Valor del Marcador:</label>
                  <img
                    src={subastaHelpIcon}
                    alt="Ayuda Valor del marcador"
                    onClick={this.popupUnidadAumento}
                  />
                </div>
                <input
                  placeholder={unidadAumentoPlaceholder}
                  type="number"
                  name="unidad_aumento"
                  required
                  onChange={this.setUnidadAumento}
                />
              </div>
            </div>
            <input
              type="submit"
              className="btn btn-secondary centered createRoom"
              value="CREAR SALA CLÁSICA"
              onClick={this.validate}
            />
          </React.Fragment>
        );
      }
    } else {
      if (this.state.matchIdFound) {
        let unidadAumentoPlaceholder = this.state.unidadAumento;
        existentMatchData = (
          <React.Fragment>
            <div className="create-room-match-data-info-container">
              <div className="create-room-match-data-block">
                <div className="create-room-match-data-title">
                  FECHA DEL PARTIDO:
                </div>
                <div className="create-room-match-data-text">
                  {this.state.existentMatchData.date}
                </div>
              </div>
              <div className="create-room-match-data-block">
                <div className="create-room-match-data-title">
                  HORA DEL PARTIDO:
                </div>
                <div className="create-room-match-data-text">
                  {this.state.existentMatchData.hour}
                </div>
              </div>
            </div>

            <div className="create-room-match-data-time-container">
              {/* <div className="hour-container">
                <label htmlFor="">Hora de la subasta:</label>
                <div className="create-room-match-data-text">
                  {this.state.horaSubasta}
                </div>
              </div> */}
              <div className="unit-container" onClick={this.popupUnidadAumento}>
                <div className="helpIcon-container">
                  <label htmlFor="">Valor del Marcador:</label>
                  <img
                    src={subastaHelpIcon}
                    alt="Ayuda Valor del Marcador"
                    onClick={this.popupUnidadAumento}
                  />
                </div>

                <input
                  placeholder={unidadAumentoPlaceholder}
                  type="number"
                  name="unidad_aumento"
                  required
                  onChange={this.setUnidadAumento}
                />
              </div>
            </div>

            <input
              type="submit"
              className="btn btn-secondary centered createRoom"
              value="CREAR SALA CLÁSICA"
              onClick={this.validate}
            />
          </React.Fragment>
        );
      }
    }
    return (
      <React.Fragment>
        <div className="create-room-container">
          <div className="create-room-form-container">
            <div className="subasta-create-rooms-btn-container mobile">
              <div className="btn btn-danger-small centered">
                <Link to={"/"}>X</Link>
              </div>
            </div>
            <div className="create-room-header-container">
              <h2 className="create-room-title">Crear Sala Clásica</h2>
              <div className="create-room-icon-container">
                <img
                  src={createClassicIconWhite}
                  alt="Crear Sala Tipo Clásica"
                />
              </div>
              <div className="create-room-description">
                Nombra tu sala, escoge el partido y establece el valor fijo para
                cualquier marcador.
              </div>
            </div>
            <div className="create-room-form">
              <label htmlFor="">Nombre de la sala:</label>
              <input
                type="text"
                name="nombre_sala"
                required
                onChange={this.setRoomName}
              />
              <label htmlFor="">Campeonato:</label>
              <select
                ref="championShipValue"
                onChange={this.setChampionShipId}
                defaultValue={this.state.initialChampionShipName}
              >
                <option disabled value={this.state.initialChampionShipName}>
                  Selecciona el campeonato...
                </option>
                {optionsItems}
              </select>

              {matchListSelectForm}
              {customMatchForm}
              {horaSubasta}
              {existentMatchData}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateClassicRoom;
